import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import DetailsIcon from "@material-ui/icons/Details";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Input from "@material-ui/core/Input";

const TagFilterUI = ({ allTags, tagFilters, onChange, toggleAndOr, andOrVal, renderValue }) => {
    const menuItems = allTags.map(name => (
        <MenuItem key={name} value={name}>
            <Checkbox checked={tagFilters.indexOf(name) > -1} />
            <ListItemText primary={name} />
        </MenuItem>
    ))
    return (
        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <DetailsIcon fontSize="small" />
          <div
            style={{
              flex: 1
            }}
          >
            <FormControl>
              {/* <InputLabel htmlFor="select-multiple-checkbox">Tag</InputLabel> */}
              <Select
                multiple
                value={tagFilters}
                onChange={onChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={renderValue}
                /* MenuProps={MenuProps} */
              >
                {menuItems}
              </Select>
            </FormControl>
          </div>
          <div
            onClick={toggleAndOr}
          >
            <Typography variant="body2">{andOrVal}</Typography>
          </div>
        </div>
      );
};

export default TagFilterUI;
