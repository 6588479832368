import { DELETE_NODE, ADD_NEW_NODE } from "../actions";

const NavStack = (
  state = [
    // {
    //   routeName: "NewNode",
    //   params: {
    //     bufferID: "action.bufferID",
    //     nodeID: "action.nodeID",
    //     isNew: true
    //   }
    // }
  ],
  action
) => {
  let nextState;
  switch (action.type) {
    case ADD_NEW_NODE:
      nextState = state.slice(0);
      const lastItem = nextState[nextState.length - 1];
      const nextItem = {
        routeName: "NewNode",
        params: {
          bufferID: action.bufferID,
          nodeID: action.nodeID,
          isNew: true
        }
      };
      if (lastItem && lastItem.params.isNew === true) {
        nextState[nextState.length - 1] = nextItem;
      } else {
        nextState.push(nextItem);
      }
      break;
    case "NavStack:Push":
      nextState = state.slice(0);
      nextState.push({
        routeName: "---",
        params: {
          bufferID: action.bufferID,
          nodeID: action.nodeID,
          isNew: action.isNew
        }
      });
      break;
    // case DELETE_NODE:
    // nextState = StacksOverTabs.router.getStateForAction(
    //   NavigationActions.back(),
    //   state
    // );
    // break;
    case "NavStack:Pop":
      nextState = state.slice(0);
      nextState.pop();
      break;
    case "NavStack:Flush":
      nextState = [];
      break;
    default:
      // nextState = StacksOverTabs.router.getStateForAction(action, state);
      nextState = state;
      break;
  }
  return nextState || state;
};

export default NavStack;
