import React from "react";

import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";

const SearchFieldUI = ({ value, onChange }) => {
  return (
    <div
        style={{
            display: "flex",
            flexDirection: "row"
        }}>
        <TextField
            style={{
                flex: 1
            }}
            value={value}
            onChange={onChange}
        />
        <SearchIcon fontSize="small" />
    </div>);
};

export default SearchFieldUI;
