import React, { Component } from "react";
import { connect } from "react-redux";

// import SortableListView from 'react-native-sortable-listview';

import List from "./List";
import Typography from "@material-ui/core/Typography";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import ScheduleIcon from "@material-ui/icons/Schedule";
import KitchenIcon from "@material-ui/icons/Kitchen";
import BookIcon from "@material-ui/icons/Book";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import TableChartIcon from "@material-ui/icons/TableChart";
import ListIcon from "@material-ui/icons/List";
import CodeIcon from "@material-ui/icons/Code";

import {
  addNewNodePlanning,
  addNewNodePropDrawer,
  addNewNodeLogbook,
  addNewNodeParagraph,
  insertNewNodeProp,
  updateNodeProp,
  removeNodeProp,
  insertNewNodeLogNote,
  updateNodeLogNote,
  removeNodeLogNote,
  updateNodeParagraph,
  updateSectionItemIndex,
  removeSectionItemAtIndex
} from "../actions";

import { getNode } from "../selectors";

import OrgBlock from "../components/OrgBlock";
import OrgPlanning from "../components/OrgPlanning";
import OrgTable from "../components/OrgTable";
import OrgPlainList from "../components/OrgPlainList";
import OrgDrawer from "../components/OrgDrawer";
import OrgLogbook from "../components/OrgLogbook";
import OrgBody from "../components/OrgBody";
import SortableList from "../components/SortableList";

import OrgNodeUtil from "../utilities/OrgNodeUtil";
import OrgTimestampUtil from "../utilities/OrgTimestampUtil";
import timestampStringNow from "../utilities/utils";

const MyContext = React.createContext({ somefunc: () => { console.log("eat shit") } });

class OrgSection extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedIndex: 0, isLocked: true };
    this.myRef = React.createRef();
  }

  render() {
    const {
      bufferID,
      nodeID,
      node,
      tree,
      onAddPlanningPress,
      onAddPropDrawerPress,
      onAddLogbookPress,
      onAddParagraphPress,
      onAddProp,
      onUpdateProp,
      onRemoveProp,
      onAddLogNote,
      onUpdateLogNote,
      onRemoveLogNote,
      onUpdateNodeParagraph,
      onRowMoved,
      onRowRemove
    } = this.props;

    const mode = node ? "headline" : "file";
    const entity = node || tree;

    const inactiveButton = icon => {
      return <div style={{ flex: 1, padding: 8 }}>{icon}</div>;
    };

    const activeButton = (icon, action) => {
      return (
        <div style={{ flex: 1, padding: 8 }} onClick={action}>
          {icon}
        </div>
      );
    };

    const toolbar = (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{ flex: 1 }}
          onClick={() => {
            this.setState({ isLocked: !this.state.isLocked });
          }}
        >
          {this.state.isLocked ? (
            <LockIcon fontSize="small" />
          ) : (
              <LockOpenIcon fontSize="small" />
            )}
        </div>

        {mode === "headline"
          ? [
            <div style={{ flex: 1 }}>
              {OrgNodeUtil.getPlanning(entity)
                ? inactiveButton(
                  <ScheduleIcon fontSize="small" color="disabled" />
                )
                : activeButton(<ScheduleIcon fontSize="small" />, () => {
                  onAddPlanningPress(bufferID, nodeID);
                })}
            </div>,
            <div style={{ flex: 1 }}>
              {OrgNodeUtil.getPropDrawer(entity)
                ? inactiveButton(
                  <KitchenIcon fontSize="small" color="disabled" />
                )
                : activeButton(<KitchenIcon fontSize="small" />, () => {
                  onAddPropDrawerPress(bufferID, nodeID);
                })}
            </div>,
            <div style={{ flex: 1 }}>
              {OrgNodeUtil.getLogbook(entity)
                ? inactiveButton(
                  <BookIcon fontSize="small" color="disabled" />
                )
                : activeButton(<BookIcon fontSize="small" />, () => {
                  onAddLogbookPress(bufferID, nodeID);
                })}
            </div>,
            <div style={{ flex: 1 }}>
              {activeButton(<FormatAlignLeftIcon fontSize="small" />, () => {
                onAddParagraphPress(bufferID, nodeID);
              })}
            </div>
          ]
          : null}

        <div style={{ flex: 1 }} onClick={() => console.log("foo")}>
          <TableChartIcon fontSize="small" />
        </div>

        <div style={{ flex: 1 }} onClick={() => console.log("foo")}>
          <ListIcon fontSize="small" />
        </div>

        <div style={{ flex: 1 }} onClick={() => console.log("foo")}>
          <CodeIcon fontSize="small" />
        </div>
      </div>
    );

    if (entity.section && entity.section.children) {
      const data = entity.section.children.reduce((m, c, idx) => {
        c.isLocked = false;
        m[JSON.stringify(c) + "-" + idx] = c;
        return m;
      }, {});

      const order = Object.keys(data);

      return (
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#cccccc",
            overflow: "hidden"
          }}
        >
          {toolbar}
          {this.state.isLocked ? (
            /* <FlatList */
            /*   style={{ flex: 1 }} */
            /*   data={Object.entries(data)} */
            /*   keyExtractor={(item, idx) => { */
            /*     return item.type + idx; */
            /*   }} */
            /*   renderItem={(({ item }, x, y, z) => { */
            /*     let b = item[0]; */
            /*     let row = item[1]; */
            /*     return ( */
            /*       <RowComponent */
            /*         idx={parseInt(b[b.length - 1])} */
            /*         c={row} */
            /*         data={row} */
            /*         node={entity} */
            /*         nodeID={nodeID} */
            /*         bufferID={bufferID} */
            /*         isLocked={true} */
            /*         onRemoveSectionItem={idx => () => { */
            /*           console.log("no-op"); */
            /*         }} */
            /*         eventHandlers={{ */
            /*           onAddProp: onAddProp(bufferID, nodeID), */
            /*           onUpdateProp: onUpdateProp(bufferID, nodeID), */
            /*           onRemoveProp: onRemoveProp(bufferID, nodeID), */
            /*           onAddLogNote: onAddLogNote(bufferID, nodeID), */
            /*           onUpdateLogNote: onUpdateLogNote(bufferID, nodeID), */
            /*           onRemoveLogNote: onRemoveLogNote(bufferID, nodeID), */
            /*           onUpdateNodeParagraph: onUpdateNodeParagraph( */
            /*             bufferID, */
            /*             nodeID, */
            /*             parseInt(b[b.length - 1]) */
            /*           ) */
            /*         }} */
            /*       /> */
            /*     ); */
            /*   }).bind(this)} */
            /* /> */
            <div style={{ overflowY: "scroll" }}>
              <List
                scrollable={true}
                data={Object.entries(data)}
                renderItem={((item, idx) => {
                  let b = item[0];
                  let row = item[1];
                  console.log("ITEM", item, idx);
                  return (
                    <RowComponent
                      k={row.type + idx}
                      key={row.type + idx}
                      idx={parseInt(b[b.length - 1])}
                      c={row}
                      data={row}
                      node={entity}
                      nodeID={nodeID}
                      bufferID={bufferID}
                      isLocked={false}
                      onRemoveSectionItem={idx => () => {
                        console.log("no-op");
                      }}
                      eventHandlers={{
                        onAddProp: onAddProp(bufferID, nodeID),
                        onUpdateProp: onUpdateProp(bufferID, nodeID),
                        onRemoveProp: onRemoveProp(bufferID, nodeID),
                        onAddLogNote: onAddLogNote(bufferID, nodeID),
                        onUpdateLogNote: onUpdateLogNote(bufferID, nodeID),
                        onRemoveLogNote: onRemoveLogNote(bufferID, nodeID),
                        onUpdateNodeParagraph: onUpdateNodeParagraph(
                          bufferID,
                          nodeID,
                          parseInt(b[b.length - 1])
                        )
                      }}
                    />
                  );
                }).bind(this)}
              />
            </div>
          ) : (
              <div style={{ overflowY: "scroll" }}>
                <SortableList
                  data={Object.entries(data)}
                  renderItem={((row, idx) => {
                    /* console.log(row, a, b, c); */
                    /* const idx = parseInt(b[b.length - 1]); */
                    return (
                      <RowComponent
                        idx={idx}
                        key={row[0]}
                        c={row[1]}
                        data={row[1]}
                        node={entity}
                        nodeID={nodeID}
                        bufferID={bufferID}
                        isLocked={false}
                        onRemoveSectionItem={() => {
                          onRowRemove(bufferID, nodeID, idx);
                        }}
                        eventHandlers={{
                          onAddProp: onAddProp(bufferID, nodeID),
                          onUpdateProp: onUpdateProp(bufferID, nodeID),
                          onRemoveProp: onRemoveProp(bufferID, nodeID),
                          onAddLogNote: onAddLogNote(bufferID, nodeID),
                          onUpdateLogNote: onUpdateLogNote(bufferID, nodeID),
                          onRemoveLogNote: onRemoveLogNote(bufferID, nodeID),
                          onUpdateNodeParagraph: onUpdateNodeParagraph(
                            bufferID,
                            nodeID,
                            idx
                          )
                        }}
                      />
                    );
                  }).bind(this)}
                />
              </div>
            )
            /* <SortableListView */
            /*   limitScrolling={true} */
            /*   style={{ flex: 1 }} */
            /*   data={data} */
            /*   order={order} */
            /*   onRowMoved={onRowMoved(bufferID, nodeID)} */
            /*   renderRow={((row, a, b, c) => { */
            /*     const idx = parseInt(b[b.length - 1]); */
            /*     return ( */
            /*       <RowComponent */
            /*         idx={idx} */
            /*         key={b} */
            /*         c={row} */
            /*         data={row} */
            /*         node={entity} */
            /*         nodeID={nodeID} */
            /*         bufferID={bufferID} */
            /*         isLocked={false} */
            /*         onRemoveSectionItem={() => { */
            /*           onRowRemove(bufferID, nodeID, idx); */
            /*         }} */
            /*         eventHandlers={{ */
            /*           onAddProp: onAddProp(bufferID, nodeID), */
            /*           onUpdateProp: onUpdateProp(bufferID, nodeID), */
            /*           onRemoveProp: onRemoveProp(bufferID, nodeID), */
            /*           onAddLogNote: onAddLogNote(bufferID, nodeID), */
            /*           onUpdateLogNote: onUpdateLogNote(bufferID, nodeID), */
            /*           onRemoveLogNote: onRemoveLogNote(bufferID, nodeID), */
            /*           onUpdateNodeParagraph: onUpdateNodeParagraph( */
            /*             bufferID, */
            /*             nodeID, */
            /*             idx */
            /*           ) */
            /*         }} */
            /*       /> */
            /*     ); */
            /*   }).bind(this)} */
            /* /> */
          }
        </div>
      );
    } else {
      return <div style={{ flex: 1 }}>{toolbar}</div>;
    }
  }
}

const eoo = (sortHandlers, isLocked, onRemoveSectionItem) => bar => {
  if (isLocked) {
    return bar;
  } else {
    return (
      // <TouchableHighlight
      //   underlayColor={"#eee"}
      //   style={{
      //     // paddingRight: 50, //25,
      //     backgroundColor: "#F8F8F8",
      //     // borderBottomWidth: 1,
      //     borderColor: "#eee"
      //   }}
      //   {...sortHandlers}
      // >

      <div style={{ flexDirection: "row" }}>
        {/* <TouchableHighlight style={{ flex: 1 }} onClick={onRemoveSectionItem}> */}
        <div>
          <Typography variant="body2">{"remove"}</Typography>
        </div>
        {/* </TouchableHighlight> */}
        <div style={{ flex: 2 }}>{bar}</div>
        <div style={{ flex: 1 }}>
          <Typography variant="body2">{"move"}</Typography>
        </div>
      </div>
      // </TouchableHighlight>
    );
  }
};

class RowComponent extends React.Component {
  render() {
    let ret = null;
    let {
      k,
      c,
      isLocked,
      node,
      bufferID,
      nodeID,
      sortHandlers,
      eventHandlers,
      onRemoveSectionItem,
      idx
    } = this.props;
    let foo = eoo(sortHandlers, isLocked, onRemoveSectionItem);
    console.log("PARAGRAPH?", c.type, c);
    switch (c.type) {
      case "org.planning": //  schedule MaterialIcons
        ret = ( ///foo(
          <div key={k} /* style={[appStyles.container, appStyles.border]} */>
            <OrgPlanning
              bufferID={bufferID}
              nodeID={nodeID}
              isCollapsed={true}
            />
          </div>
        );
        //);
        break;
      case "org.propDrawer": // drawer SimpleLineIcons
        ret = ( //foo(
          <div key={k} /* style={[appStyles.container, appStyles.border]} */>
            <OrgDrawer
              drawer={OrgNodeUtil.getPropDrawer(node)}
              isCollapsed={true}
              /* onAddProp={onAddProp(bufferID, nodeID)} */
              /* onUpdateProp={onUpdateProp(bufferID, nodeID)} */
              /* onRemoveProp={onRemoveProp(bufferID, nodeID)} */
              {...eventHandlers}
            />
          </div>
        );
        //);
        break;
      case "org.logbook": // book FontAwesome
        ret = ( //foo(
          <div key={k} /* style={[appStyles.container, appStyles.border]} */>
            <OrgLogbook
              log={OrgNodeUtil.getLogbook(node)}
              isCollapsed={true}
              /* onAddLogNote={onAddLogNote(bufferID, nodeID)} */
              /* onUpdateLogNote={onUpdateLogNote(bufferID, nodeID)} */
              /* onRemoveLogNote={onRemoveLogNote(bufferID, nodeID)} */
              {...eventHandlers}
            />
          </div>
        );
        //);
        break;
      case "org.paragraph": // paragraph FontAwesome
        ret = ( //foo(
          <OrgBody
            key={k}
            /* onUpdateNodeParagraph={onUpdateNodeParagraph( */
            /*   bufferID, */
            /*   nodeID, */
            /*   idx */
            /* )} */
            {...eventHandlers}
            text={c.value.join("\n")}
          />
        );
        //);
        break;
      case "org.keyword": //
        ret = <div key={k}>{c.key + " :: " + c.value}</div>;
        break;
      case "org.plainList": // list Foundation
        const { items } = c;
        ret = <OrgPlainList key={k} data={items} eventHandlers={eventHandlers} />;
        break;
      case "org.table": // table MaterialCommunityIcons
        ret = <OrgTable key={k} rows={c.rows} />;
        break;
      case "org.block": // code FontAwesome
        const { name, data, contents } = c;
        ret = <OrgBlock name={name} data={data} contents={contents} />;
        break;
      default:
        console.log("^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^");
        console.log(c.type);
        console.log(c);
        break;
    }
    return ret;
  }
}

const mapStateToProps = (state, ownProps) => {
  //const params = ownProps.navigation.state.params;
  const { bufferID, nodeID } = ownProps;
  console.log("BOOYAH!! ", state.orgBuffers[bufferID]);
  const tree = state.orgBuffers[bufferID].orgTree;
  const node = nodeID ? getNode(state, ownProps) : null;
  return {
    bufferID,
    nodeID,
    node,
    tree
    // isNew
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddPlanningPress: (bufferID, nodeID) => {
      console.log("planning press");
      dispatch(addNewNodePlanning(bufferID, nodeID));
    },
    onAddPropDrawerPress: (bufferID, nodeID) => {
      dispatch(addNewNodePropDrawer(bufferID, nodeID));
    },
    onAddLogbookPress: (bufferID, nodeID) => {
      dispatch(addNewNodeLogbook(bufferID, nodeID));
    },
    onAddParagraphPress: (bufferID, nodeID) => {
      dispatch(addNewNodeParagraph(bufferID, nodeID));
    },
    onAddProp: (bufferID, nodeID) => () => {
      dispatch(insertNewNodeProp(bufferID, nodeID));
    },
    onUpdateProp: (bufferID, nodeID) => (idx, oldPropKey, propKey, propVal) => {
      dispatch(
        updateNodeProp(bufferID, nodeID, idx, oldPropKey, propKey, propVal)
      );
    },
    onRemoveProp: (bufferID, nodeID) => propKey => {
      dispatch(removeNodeProp(bufferID, nodeID, propKey));
    },
    onAddLogNote: (bufferID, nodeID) => () => {
      const nowStr = OrgTimestampUtil.serialize(OrgTimestampUtil.now());
      dispatch(insertNewNodeLogNote(bufferID, nodeID, nowStr));
    },
    onUpdateLogNote: (bufferID, nodeID) => (idx, text) => {
      dispatch(updateNodeLogNote(bufferID, nodeID, idx, text));
    },
    onRemoveLogNote: (bufferID, nodeID) => idx => {
      dispatch(removeNodeLogNote(bufferID, nodeID, idx));
    },
    onUpdateNodeParagraph: (bufferID, nodeID, idx) => text => {
      dispatch(updateNodeParagraph(bufferID, nodeID, idx, text));
    },
    onRowMoved: (bufferID, nodeID) => e => {
      dispatch(updateSectionItemIndex(bufferID, nodeID, e.from, e.to));
    },
    onRowRemove: (bufferID, nodeID, idx) => {
      console.log("suckit", bufferID, nodeID, idx);
      dispatch(removeSectionItemAtIndex(bufferID, nodeID, idx));
    },
    onSomeshitCheckbox: (bufferID, nodeID) => () => {
      console.log("SUCK MY DICK", bufferID, nodeID)
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgSection);
