import React from "react";
import { connect } from "react-redux";
import logo from "./logo.svg";
import "./App.css";
import DropboxDataSource from "./utilities/DropboxDataSource";

import localforage from "localforage";

import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Icon from "@material-ui/core/Icon";
import SyncIcon from "@material-ui/icons/Sync";
import AddIcon from "@material-ui/icons/Add";
import RestoreIcon from "@material-ui/icons/Restore";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeIcon from "@material-ui/icons/Home";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import LoadingScreen from "./screens/LoadingScreen";
import HomeScreen from "./screens/HomeScreen";
import SettingsScreen from "./screens/SettingsScreen";
import NodeDetailScreen from "./screens/NodeDetailScreen";
import CalendarScreen from "./screens/CalendarScreen";

import { BASE_ORG_DIR_KEY } from "./constants";
import { getInboxBufferID } from "./selectors";

import {
  loadOrgFiles,
  registerDbxAccessToken,
  addNewNode,
  doCloudUpload
} from "./actions";

const serviceName = "DBX";

const loadData = (accessToken, baseDir) => {
  const config = {
    url: "https://api.dropboxapi.com/2/files/list_folder",
    method: "POST",
    body: JSON.stringify({
      path: baseDir,
      recursive: true
    }),
    headers: {
      Authorization: "Bearer " + accessToken,
      "Content-Type": "application/json"
    }
  };
  const { url, method, body, headers } = config;

  const getMore = cursor => {
    return new Promise((resolve, reject) => {
      fetch("https://api.dropboxapi.com/2/files/list_folder/continue", {
        method,
        body: JSON.stringify({ cursor: cursor }),
        headers
      })
        .then(res => {
          return res.json();
        })
        .then(json => {
          console.log(json);
          if (json.has_more === true) {
            getMore(json.cursor)
              .then(res => {
                console.log("DONE");
                resolve(json.entries.concat(res));
              })
              .catch(e => {
                reject(e);
              });
          } else {
            console.log("DONE");
            resolve(json.entries);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  return new Promise((resolve, reject) => {
    fetch(url, { method, body, headers })
      .then(res => res.json())
      .then(json => {
        //withResults(json, resolve, reject);
        if (json.has_more === true) {
          getMore(json.cursor)
            .then(result => {
              resolve(json.entries.concat(result));
            })
            .catch(e => {
              reject(e);
            });
        } else {
          resolve(json.entries);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: null,
      value: "home",
      isLoading: false,
      baseDir: "",
      speedDialOpen: false
    };
  }

  async componentDidMount() {
    const { accessToken, orgFiles, loadOrgFiles } = this.props;
    if (accessToken) {
      // const baseDir = await localforage.getItem(BASE_ORG_DIR_KEY);
      if (orgFiles) {
        console.log("GOT ORG FILES:", orgFiles);
        this.setState({ isLoading: true });
        await loadOrgFiles(orgFiles);
        // const data = await loadData(accessToken, baseDir.path);
        console.log("LOADED DATA");
        // const initFile = data.filter(
        //   d => d.path_lower === `${baseDir}/init.org`
        // )[0];
        // if (initFile) {
        //   console.log(initFile);
        //   const dbx = new DropboxDataSource({ accessToken });
        //   const foo = await dbx.loadParseOrgFilesAsync(initFile.path_lower);
        //   console.log(foo);
        //   await this.props.loadOrgFiles([`${baseDir}/init.org`]);
        //   this.setState({ foo: data, isLoading: false });
        // } else {
        this.setState({ foo: [], isLoading: false });
        // }
      } else {
        this.setState({ value: "settings" });
      }
    } else {
      this.setState({ value: "settings" });
    }
  }

  render() {
    const {
      accessToken,
      navStack,
      syncWithCloud,
      onAddOne,
      inboxBufferID
    } = this.props;
    const { foo, value, isLoading, speedDialOpen } = this.state;

    const stack = navStack.map((i, idx) => {
      return <NodeDetailScreen key={idx} />;
    });

    const bottomNavA = (
      <BottomNavigation
        value={value}
        onChange={handleChange.bind(this)}
        className={"foo"}
      >
        <BottomNavigationAction
          label="Settings"
          value="settings"
          icon={<SettingsIcon />}
        />
      </BottomNavigation>
    );

    const bottomNavB = (
      <BottomNavigation
        value={value}
        onChange={handleChange.bind(this)}
        className={"foo"}
      >
        <BottomNavigationAction label="Home" value="home" icon={<HomeIcon />} />
        <BottomNavigationAction
          label="Calendar"
          value="calendar"
          icon={<CalendarTodayIcon />}
        />
        <BottomNavigationAction
          label="Ledger"
          value="ledger"
          icon={<AttachMoneyIcon />}
        />
        <BottomNavigationAction
          label="Settings"
          value="settings"
          icon={<SettingsIcon />}
        />
      </BottomNavigation>
    );

    function handleChange(event, newValue) {
      this.setState({ value: newValue });
    }

    const handleSpeedDialOpen = () => {
      this.setState({ speedDialOpen: true });
    };

    const handleSpeedDialClick = () => {
      this.setState({ speedDialOpen: !speedDialOpen });
    };

    const handleSpeedDialClose = () => {
      this.setState({ speedDialOpen: false });
    };

    const handleAddClick = () => {
      console.log("add!");
      onAddOne(inboxBufferID);
      this.setState({ speedDialOpen: false });
    };

    const handleSyncClick = () => {
      console.log("synce!");
      syncWithCloud(
        x => {
          console.log("success!?", x);
        },
        x => {
          console.log("err!?", x);
        }
      );
      this.setState({ speedDialOpen: false });
    };
    let screen;
    switch (value) {
      case "home":
        screen = <HomeScreen data={foo} />;
        break;
      case "settings":
        screen = <SettingsScreen data={foo} />;
        break;
      case "calendar":
        screen = <CalendarScreen data={foo} />;
        break;
      default:
        screen = null;
        break;
    }
    return isLoading ? (
      <LoadingScreen />
    ) : foo ? (
      <div
        style={{
          height: "100vh",
          backgroundColor: "#4590e1",
          display: "flex",
          flexDirection: "column"
        }}
        /* container */
        /* direction="column" */
        className="App"
      >
        <div
          style={{
            overflowX: "hidden",
            overflowY: "hidden",
            flex: 1,
            WebkitOverflowScrolling: "touch"
          }}
        >
          {screen}
        </div>
        <div
          height={100}
          style={{
            width: "100%",
            /* position: "fixed", */
            /* bottom: 0 */
            zIndex: 10
          }}
        >
          {bottomNavB}
        </div>
        {stack}
        <SpeedDial
          style={{ position: "absolute", top: "61vh", right: "5vw" }}
          ariaLabel="SpeedDial example"
          /* className={speedDialClassName} */
          hidden={false}
          icon={<SpeedDialIcon />}
          onBlur={handleSpeedDialClose}
          onClick={handleSpeedDialClick}
          onClose={handleSpeedDialClose}
          onFocus={handleSpeedDialOpen}
          onMouseEnter={handleSpeedDialOpen}
          onMouseLeave={handleSpeedDialClose}
          open={speedDialOpen}
          direction={"up"}
        >
          <SpeedDialAction
            key={"add"}
            icon={<AddIcon />}
            tooltipTitle={"add"}
            onClick={handleAddClick}
          />
          <SpeedDialAction
            key={"sync"}
            icon={<SyncIcon />}
            tooltipTitle={"sync"}
            onClick={handleSyncClick}
          />
        </SpeedDial>
      </div>
    ) : (
      <Grid container direction="column" className="App">
        <Box height="100%">{screen}</Box>
        <Box
          height={100}
          style={{
            width: "100%",
            position: "fixed",
            bottom: 0
          }}
        >
          {bottomNavA}
        </Box>
      </Grid>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const inboxBufferID = getInboxBufferID(state);
  return {
    accessToken: state.dbxAccessToken,
    baseDir: state.settings.baseDir,
    orgFiles: state.settings.orgFiles,
    // inboxFileOk: state.settings.inboxFile.isOk,
    // nav: state.nav
    navStack: state.navStack,
    inboxBufferID
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadOrgFiles: filePaths => {
      dispatch(loadOrgFiles(filePaths));
    },
    syncWithCloud: (onSuccess, onError) => {
      dispatch(doCloudUpload(onSuccess, onError));
    },
    // onReceiveDbxAccessToken: token => {
    //   dispatch(registerDbxAccessToken(token));
    // }
    onAddOne: bufferID => {
      dispatch(addNewNode(bufferID));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
// export default App;
