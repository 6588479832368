import React, { Component, useState } from "react";
import List from "./List";

import Box from "@material-ui/core/Box";
import ListIcon from "@material-ui/icons/List";

class OrgCheckBox extends Component {
  render() {
    const { value, onClick } = this.props;
    return (<span onClick={onClick}>
      [{value}]
    </span>)
  }
}

const renderItem = (i, idx, eventHandlers) => {
  const { counter, bullet, checkbox, value, list } = i;
  const childList = list ? <OrgPlainList data={list} /> : null;
  const { onSomeshitCheckbox } = eventHandlers
  console.log(eventHandlers)
  console.log("ON SOMESHIT CHECKBOX", onSomeshitCheckbox)
  const checkboxRender = checkbox ? <OrgCheckBox value={checkbox} onClick={() => { console.log("HELLO!!!!??"); onSomeshitCheckbox() }} /> : '';
  return (<React.Fragment key={value + idx}>
    <li style={{ fontFamily: "Fira Code" }} >
      <span>
        {counter ? <span className="counter">counter</span> : null}
        <span>{bullet}</span>
        <span>{checkboxRender}</span>
        <span>{value}</span>
      </span>
    </li>
    {childList}
  </React.Fragment>)
}

function OrgPlainList({ data, eventHandlers }) {

  const [isCollapsed, setIsCollapsed] = useState(false)
  const toggleCollapse = () => setIsCollapsed(!isCollapsed)

  if (isCollapsed) {
    return (
      <div onClick={toggleCollapse}>
        <div
          className="OrgBody"
          style={{
            flexDirection: "row",
            backgroundColor: "#cccccc"
          }}
        >
          <ListIcon fontSize="small" style={{ marginLeft: 5 }} />
        </div>
      </div>
    );
  } else {
    return (
      <Box>
        <Box
          className="OrgPlainList"
          style={{
            flexDirection: "row",
            backgroundColor: "#cccccc"
          }}
        >
          <Box onClick={toggleCollapse}>
            {isCollapsed ?
              (<ListIcon fontSize="small" style={{ marginLeft: 5 }} />) :
              (<ListIcon fontSize="small" style={{ marginLeft: 5 }} />)}
          </Box>
        </Box>
        <ul>
          <List data={data} renderItem={renderItem} eventHandlers={eventHandlers} />
        </ul>
      </Box>
    )
  }
}

export default OrgPlainList;
