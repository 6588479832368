import React from "react";

import Typography from "@material-ui/core/Typography";

const HabitList = ({ habits, onItemClick }) => (
  <div style={{ width: "50vw" }}>
    {habits.map((h, idx) => {
      return (
        <div
          key={h.id}
          onClick={() => {
            onItemClick(h);
          }}
        >
          <Typography
            variant="body2"
            style={{
              textAlign: "right",
              fontSize: 12,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {h.title}
          </Typography>
        </div>
      );
    })}
  </div>
);

export default HabitList;
