import React from "react";
import { connect } from "react-redux";

import {
  getHabitBufferID,
  // getHabitMatrix,
  makeGetHabitMatrixRow
} from "../../selectors";

class HabitGridRow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { data, blockWidth, rangeStart, rangeEnd } = this.props;
    return (
      <div
        style={{
          fontFamily: "Fira Code",
          fontSize: 12,
          marginTop: "1px",
          whiteSpace: "nowrap"
        }}
      >
        {data.map((c, idx) => {
          const color = {
            "-": "red",
            b: "blue",
            g: "green",
            y: "yellow",
            e: "purple"
          }[c.status];
          return (
            <div
              key={idx}
              style={{
                backgroundColor: color,
                width: blockWidth,
                display: "inline-block"
              }}
            >
              {c.done ? "*" : "-"}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {rangeStart, rangeEnd} = ownProps
  const habitBufferId = getHabitBufferID(state);

  const rowData = makeGetHabitMatrixRow(ownProps.habitId, rangeStart, rangeEnd)(state, {
    bufferID: habitBufferId,
    nodeID: ownProps.habitId,
    date: ownProps.date
  });

  return {
    data: rowData
  };
};

export default connect(
  mapStateToProps,
  null
)(HabitGridRow);
