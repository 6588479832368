import React, { Component } from "react";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";

import OrgTodoKeywordEditable from "./OrgTodoKeywordEditable";
import OrgTags from "./OrgTagsEditable";

import { updateNodeHeadlineTitle } from "../actions";

import { getNode } from "../selectors";

class OrgHeadlineEditable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { bufferID, node, autoFocus, onHeadlineEndEditing } = this.props;

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{}}>
          <OrgTodoKeywordEditable
            keyword={node.keyword}
            nodeID={node.id}
            bufferID={bufferID}
          />
        </div>
        <div style={{ flex: 1, overflow: "hidden", whiteSpace: "nowrap" }}>
          <TextField
            id="standard-name"
            fullWidth
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
            variant="outlined"
            value={node.title}
            autoFocus={autoFocus}
            onChange={e => {
              onHeadlineEndEditing(bufferID, node.id, e.target.value);
            }}
            margin="dense"
          />
        </div>
        <div style={{}}>
          <OrgTags bufferID={bufferID} nodeID={node.id} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { bufferID, nodeID, autoFocus } = ownProps;
  console.log(bufferID, nodeID, autoFocus);
  const node = getNode(state, ownProps);
  return {
    bufferID,
    nodeID,
    node,
    autoFocus: autoFocus || false
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onHeadlineEndEditing: (bufferID, nodeID, text) => {
      dispatch(updateNodeHeadlineTitle(bufferID, nodeID, text));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgHeadlineEditable);
