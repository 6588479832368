import React, { Component } from "react";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import { StyleSheet, Typography, TouchableHighlight, Box } from 'react-native';
// import { NavigationActions, StackActions } from 'react-navigation';
// import Swipeout from 'react-native-swipeout';

import OrgTodoKeywordEditable from "./OrgTodoKeywordEditable";

import {
  addNewNode,
  cycleNodeCollapse,
  deleteNode
  // updateNodeHeadlineContent
} from "../actions";

import OrgTags from "./OrgTagsEditable";

// import appStyles from "../styles";

import { getNode } from "../selectors";

import { colorForKeyword } from "../utilities/utils";

// import { withNavigation } from "react-navigation";

// const OrgHeadlineUtil = require("org-parse").OrgHeadline;

// style={{ width: 10*(5/8) + "em" }}
class OrgHeadline extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      bufferID,
      node,
      nodeID,
      isLocked,
      onNodeTitlePress,
      onNodeTitlePressUnlocked,
      onDeleteNodePress,
      onAddOnePress,
      selected,
      onSelect
    } = this.props;
    const keyword = node.keyword;
    const tags = node.tags;
    const border = selected ? "1px solid red" : "none";

    console.log("_______________", selected);
    if (isLocked) {
      return (
        <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
          <div>
            <Typography
              variant="body2"
              style={{
                padding: "0px 5px 0px 5px",
                /*   /\* appStyles.baseText, *\/ */
                backgroundColor: colorForKeyword(keyword ? keyword : "none"),
                color: "#fff"
                /*   { */
                /*     backgroundColor: keyword */
                /*       ? /\* "#f00" *\/ OrgHeadlineUtil.colorForKeyword(keyword) */
                /*       : "#fff" */
                /*   } */
              }}
            >
              {keyword ? keyword : "none"}
            </Typography>
          </div>

          {/* <TouchableHighlight */}
          {/*   style={{ flex: 4 }} */}
          {/*   onClick={() => { */}
          {/*     onNodeTitlePress(bufferID, nodeID); */}
          {/*   }} */}
          {/* > */}

          <div
            style={{
              flex: 1,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
            onClick={() => {
              onNodeTitlePress(bufferID, nodeID);
            }}
          >
            <Typography
              variant="body2"
              noWrap
              style={{
                margin: "0 10px 0 10px"
                /*   , */
                /*   /\* appStyles.baseText *\/ { fontSize: 12, color: "#000" } */
              }}
            >
              {node.title}
            </Typography>
          </div>
          {/* </TouchableHighlight> */}
          {tags && (
            <div>
              <Typography variant="body2" style={{ fontSize: ".5rem" }}>
                {tags.join(":")}
              </Typography>
            </div>
          )}
        </div>
      );
    } else {
      return (
        // <Swipeout
        //   style={{ flex: 1 }}
        //   right={[
        //     {
        //       text: "deleteNode",
        //       onClick: () => {
        //         onDeleteNodePress(bufferID, nodeID);
        //       }
        //     }
        //   ]}
        //   left={[
        //     {
        //       text: "addOne",
        //       onClick: () => {
        //         onAddOnePress(bufferID, nodeID, node);
        //       }
        //     }
        //   ]}
        // >
        <div style={{ display: "flex", flex: 1, flexDirection: "row", border }}>
          <div style={{ height: 17.1667 }}>
            <OrgTodoKeywordEditable
              keyword={keyword}
              nodeID={nodeID}
              bufferID={bufferID}
            />
          </div>
          {/* <TouchableHighlight */}
          {/*   style={{ flex: 4 }} */}
          {/*   onClick={() => { */}
          {/*     onNodeTitlePress(bufferID, nodeID); */}
          {/*   }} */}
          {/* > */}
          <div
            style={{
              flex: 1,
              backgroundColor: "#fff",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
            onClick={() => {
              onSelect(nodeID);
              //this.setState({selectedNodeId: nodeID})
              // console.log(bufferID, nodeID);
            }}
          >
            <Typography
              variant="body2"
              style={{
                backgroundColor: "#fff",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                margin: "0 10px 0 2px"
              }}
            >
              {node.title}
            </Typography>
          </div>
          {/* </TouchableHighlight> */}
          <Box style={{ height: 17.1667 }}>
            <OrgTags bufferID={bufferID} nodeID={node.id} />
          </Box>
        </div>
        // </Swipeout>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { bufferID, nodeID } = ownProps;
  const node = getNode(state, ownProps);
  return {
    node
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onNodeTitlePress: (bufferID, nodeID) => {
      console.log("NodeTitlePres -- ", bufferID, nodeID);
      dispatch({
        type: "NavStack:Push",
        bufferID,
        nodeID,
        isNew: false
      });
      // const pushAction = StackActions.push({
      //   routeName: "NodeDetail",
      //   params: {
      //     bufferID,
      //     nodeID
      //   }
      // });
      // ownProps.navigation.dispatch(pushAction);
    },
    onDeleteNodePress: (bufferID, nodeID) => {
      dispatch(deleteNode(bufferID, nodeID));
    },
    onAddOnePress: (bufferID, nodeID, node) => {
      dispatch(addNewNode(bufferID, nodeID, node.stars + 1));
    },
    onNodeArrowPress: (bufferID, nodeID) => {
      dispatch(cycleNodeCollapse(bufferID, nodeID));
    },
    onHeadlineEndEditing: (bufferID, nodeID, text) => {
      // dispatch(updateNodeHeadlineContent(bufferID, nodeID, text));
    }
  };
};

export default // withNavigation(
connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgHeadline);
// );
