import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
// import { withNavigation, StackActions } from 'react-navigation';

// import {
//   Button,
//   PanResponder,
//   ScrollView,
//   StyleSheet,
//   Text,
//   div,
//   View
// } from 'react-native';

import { getAllNodes, getFlattenedBufferObj } from "../selectors";
/* import { OrgTimestampUtil.momentFromObj, OrgTimestampUtil.momentToObj } from '../utilities/utils';*/

import OrgNodeUtil from "../utilities/OrgNodeUtil";
import OrgTimestampUtil from "../utilities/OrgTimestampUtil";

import Typography from "@material-ui/core/Typography";

// const styles = StyleSheet.create({
//   text: {
//     fontFamily: "space-mono",
//     fontSize: 10
//   },
//   container: {
//     flex: 1,
//     paddingTop: 15
//   }
// });

const magic = 504;
const padMaybe = n => {
  n = "" + n;
  n = n.length === 1 ? "0" + n : n;
  return n;
};

class OrgAgenda extends React.Component {
  state = {
    stageH: 0,
    dayHeight: magic / 2
  };

  render() {
    let { agendaYesterday, agendaToday, agendaTomorrow, dx } = this.props;
    let agendaKeys = ["00:00", "06:00", "09:00", "12:00", "13:00", "18:00"];
    let colors = [
      // "#92021C",
      // "#EC6E58",
      // "#F6BD78",
      // "#F6BD78",
      // "#956066",
      // "#564975"
      "#6696ba",
      "#e2e38b",
      "#e7a553",
      "#e7a553",
      "#7e4b68",
      "#292965"
    ];

    const entry = (d, idx) => (
      <div
        key={idx}
        style={{
          //   flex: 1
          display: "flex",
          flexDirection: "row"
        }}
      >
        <div /* {...this._panResponder.panHandlers} */>
          <Typography variant="body2" /* style={styles.text} */>{`${
            d.time
          }...... `}</Typography>
        </div>
        <div
          onClick={() => {
            this.props.onNodeTitleClick(d.bufferID, d.nodeID);
          }}
        >
          <Typography variant="body2" key={idx} /* style={styles.text} */>
            {d.content}
          </Typography>
        </div>
      </div>
    );

    const build = (data, height) => {
      const foo = agendaKeys.map((k, kidx) => {
        const listData = data.schedule[k];
        const color = colors[kidx];
        const list = !listData
          ? []
          : listData.map((d, idx) => {
              if (d.nodeID === "NOW") {
                return (
                  <div
                    /* {...this._panResponder.panHandlers} */
                    key={idx}
                    style={
                      {
                        //   flex: 1
                      }
                    }
                  >
                    <Typography
                      variant="body2"
                      style={{ color: "yellow" }}
                      key={idx}
                    >{`${
                      d.time
                    }...... now - - - - - - - - - - - - - - - - - -`}</Typography>
                  </div>
                );
              } else {
                return entry(d, idx);
              }
            });
        const header =
          k !== agendaKeys[0] ? (
            <div /*{...this._panResponder.panHandlers}*/>
              <Typography variant="body2" /* style={styles.text} */>
                {k}
              </Typography>
            </div>
          ) : (
            <div /* {...this._panResponder.panHandlers} */>
              <Typography
                variant="body2"
                style={{ color: "#fff", backgroundColor: "#000" }}
              >
                {data.headerStr}
              </Typography>
            </div>
          );
        return (
          <div
            key={k}
            style={{
              // borderColor: '#000',
              // borderWidth: 1,
              flex: list.length + 1,
              backgroundColor: color
            }}
          >
            {header}
            {list}
          </div>
        );
      });
      return (
        <div
          key={data.headerStr}
          style={{
            // borderColor: '#000',
            // borderWidth: 1,
            height: "50vh", //height,
            // flex: 1,
            display: "flex",
            flexDirection: "column"
          }}
        >
          {foo}
        </div>
      );
    };

    //{...this._panResponder.panHandlers}
    const buildSml = (data, height) => {
      const foo = agendaKeys.map((k, kidx) => {
        const listData = data.schedule[k];
        const color = colors[kidx];
        const list = !listData ? [] : listData.map((d, idx) => entry(d, idx));
        return (
          <div
            key={k}
            style={{
              // borderColor: '#000',
              // borderWidth: 1
              backgroundColor: color
            }}
          >
            {list}
          </div>
        );
      });
      return (
        <div
          key={data.headerStr}
          style={{
            // borderColor: '#000',
            // borderWidth: 1,
            height: height,
            // flex: 1,
            display: "flex",
            flexDirection: "column"
          }}
        >
          <Typography
            variant="body2"
            style={{ color: "#fff", backgroundColor: "#000" }}
          >
            {data.headerStr}
          </Typography>
          {foo}
        </div>
      );
    };

    const xxx = (
      <div
        style={{
          // borderColor: '#f00',
          // borderWidth: 1,
          flex: 1,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {/* <div */}
        {/*   style={{ */}
        {/*     flex: 1 */}
        {/*   }} */}
        {/* > */}
        {this.props.percH <= 0.5
          ? build(agendaToday, magic / 2)
          : [
              buildSml(agendaYesterday, magic / 4),
              build(agendaToday, magic / 2),
              buildSml(agendaTomorrow, magic / 4)
            ]}
        {/* </div> */}
      </div>
    );

    return (
      <div
        style={{
          flex: 1,
          // borderColor: '#000',
          // borderWidth: 1,
          overflow: "hidden",
          backgroundColor: "#000",
          height: "100%"
        }}
        /* onLayout={event => { */
        /*   const { x, y, width, height } = event.nativeEvent.layout; */
        /*   this.setState({ */
        /*     stageH: height */
        /*   }); */
        /* }} */
      >
        {xxx}
      </div>
    );
  }
}

////////////////////////////////////////////////////////////////////////////////

const hours = [0, 6, 9, 12, 13, 18, 24];

const filterRange = (ns, start, end) => {
  return ns.filter(n => {
    const scheduled = n.scheduled; //OrgNodeUtil.getScheduled(n);
    const deadline = n.deadline; //OrgNodeUtil.getDeadline(n);
    return (
      (scheduled &&
        OrgTimestampUtil.compare(scheduled, start) >= 0 &&
        OrgTimestampUtil.compare(scheduled, end) < 0) ||
      (deadline &&
        OrgTimestampUtil.compare(deadline, start) >= 0 &&
        OrgTimestampUtil.compare(deadline, end) < 0)
    );
  });
};

const buildDay = (date, headerStr, cand, start) => {
  const bar = (m, d) => {
    let res = [];
    const scheduled = d.scheduled; //OrgNodeUtil.getScheduled(d);
    const deadline = d.deadline; //OrgNodeUtil.getDeadline(d);
    const scheduledTime = scheduled.time || { hh: 0, mm: 0 };
    if (scheduled) {
      res.push({
        bufferID: d.bufferID,
        nodeID: d.nodeID,
        time: `${padMaybe(scheduledTime.hh)}:${padMaybe(scheduledTime.mm)}`,
        content: "scheduled: " + d.content
      });
    }
    if (deadline) {
      res.push({
        bufferID: d.bufferID,
        nodeID: d.nodeID,
        time: `${padMaybe(deadline.time.hh)}:${padMaybe(deadline.time.mm)}`,
        content: "deadline: " + d.content
      });
    }
    return m.concat(res);
  };
  let now = date;
  const nowStr = {
    bufferID: "NOW",
    nodeID: "NOW",
    time: `${padMaybe(now.time.hh)}:${padMaybe(now.time.mm)}:${padMaybe(
      new Date().getSeconds()
    )}`,
    content: "NOW"
  };

  let agenda = { headerStr, schedule: {} };
  for (let i = 0; i < hours.length - 1; i++) {
    const a = OrgTimestampUtil.add(start, { hours: hours[i] });
    const b = OrgTimestampUtil.add(start, { hours: hours[i + 1] });
    let foo = filterRange(cand, a, b);
    let foobar = filterRange(cand, a, b).reduce(bar, []);

    if (
      OrgTimestampUtil.compare(now, a) > 0 &&
      OrgTimestampUtil.compare(now, b) < 0
    ) {
      if (foo.length > 0) {
        if (
          OrgTimestampUtil.compare(now, foo[0].scheduled) < 0 ||
          OrgTimestampUtil.compare(now, foo[0].deadline) < 0
        ) {
          foobar.unshift(nowStr);
        } else if (OrgTimestampUtil.compare(now, foo[foo.length - 1]) > 0) {
          foobar.push(nowStr);
        } else {
          const fooFore = filterRange(foo, a, now).reduce(bar, []);
          const fooAft = filterRange(foo, now, b).reduce(bar, []);
          foobar = fooFore.concat([nowStr], fooAft);
        }
      } else {
        foobar.push(nowStr);
      }
    }

    agenda.schedule[`${padMaybe(a.time.hh)}:${padMaybe(a.time.mm)}`] = foobar;
  }
  return agenda;
};

const mapStateToProps = (state, ownProps) => {
  let agendaYesterday = [];
  let agendaToday = [];
  let agendaTomorrow = [];
  let candidates;

  let date = ownProps.date;
  let prevDate = ownProps.prevDate;
  const dt = OrgTimestampUtil.diff(date, prevDate, "days");

  const nodes = R.reduce(
    (m, n) => {
      const scheduled = OrgNodeUtil.getScheduled(n);
      const deadline = OrgNodeUtil.getDeadline(n);
      if (scheduled || deadline) {
        m.push({
          bufferID: n.bufferID,
          nodeID: n.id,
          scheduled,
          deadline,
          content: n.title
        });
      }
      return m;
    },
    [],
    getAllNodes(state)
  );

  let today = OrgTimestampUtil.clone(date);
  today.time.hh -= today.time.hh;
  today.time.mm -= today.time.mm;

  let realToday = OrgTimestampUtil.now();
  realToday.time.hh -= realToday.time.hh;
  realToday.time.mm -= realToday.time.mm;

  let diff = OrgTimestampUtil.diff(today, realToday, "days");

  const yesterday = OrgTimestampUtil.sub(today, { days: 1 });
  const tomorrow = OrgTimestampUtil.add(today, { days: 1 });
  const dayAfterTomorrow = OrgTimestampUtil.add(today, { days: 2 });

  candidates = filterRange(nodes, yesterday, dayAfterTomorrow);

  candidates.sort((a, b) => {
    const scheduledA = a.scheduled; //OrgNodeUtil.getScheduled(a);
    const deadlineA = a.deadline; //OrgNodeUtil.getDeadline(a);
    const scheduledB = b.scheduled; //OrgNodeUtil.getScheduled(b);
    const deadlineB = b.deadline; //OrgNodeUtil.getDeadline(b);
    const timeA = !scheduledA ? deadlineA : scheduledA;
    const timeB = !scheduledB ? deadlineB : scheduledB;
    //console.log(timeA !== null, timeB !== null);
    return OrgTimestampUtil.compare(timeA, timeB);
  });

  const yesStr = "-----++----YESTERDAY----------";
  const todStr = "-----++----TODAY--------------";
  const tomStr = "-----++----TOMORROW-----------";
  const dateStr = d =>
    `-----++----[${d.date.yyyy}-${padMaybe(d.date.mm)}-${padMaybe(
      d.date.dayName
    )} ${d.date.dd}]---`;
  let targYesStr, targTodStr, targTomStr;

  switch (diff) {
    case -2:
      targYesStr = dateStr(yesterday);
      targTodStr = dateStr(today);
      targTomStr = yesStr;
      break;
    case -1:
      targYesStr = dateStr(yesterday);
      targTodStr = yesStr;
      targTomStr = todStr;
      break;
    case 0:
      targYesStr = yesStr;
      targTodStr = todStr;
      targTomStr = tomStr;
      break;
    case 1:
      targYesStr = todStr;
      targTodStr = tomStr;
      targTomStr = dateStr(tomorrow);
      break;
    case 2:
      targYesStr = tomStr;
      targTodStr = dateStr(today);
      targTomStr = dateStr(tomorrow);
      break;
    default:
      targYesStr = dateStr(yesterday);
      targTodStr = dateStr(today);
      targTomStr = dateStr(tomorrow);
      break;
  }

  agendaYesterday = buildDay(
    date,
    targYesStr,
    filterRange(candidates, yesterday, today),
    yesterday
  );
  agendaToday = buildDay(
    date,
    targTodStr,
    filterRange(candidates, today, tomorrow),
    today
  );
  agendaTomorrow = buildDay(
    date,
    targTomStr,
    filterRange(candidates, tomorrow, dayAfterTomorrow),
    tomorrow
  );

  return {
    agendaYesterday,
    agendaToday,
    agendaTomorrow,
    dt
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onNodeTitleClick: (bufferID, nodeID) => {
      console.log("NodeTitlePres -- ", bufferID, nodeID);
      dispatch({
        type: "NavStack:Push",
        bufferID,
        nodeID,
        isNew: false
      });
      // const pushAction = StackActions.push({
      //   routeName: "NodeDetail",
      //   params: {
      //     bufferID,
      //     nodeID
      //   }
      // });
      // ownProps.navigation.dispatch(pushAction);
    }
  };
};

export default // withNavigation(
connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgAgenda);
// );
