import { combineReducers } from "redux";

import orgBuffers from "./OrgBuffers";
// import nav from './Nav';
import navStack from "./NavStack";
import settings from "./Settings";
import dbxAccessToken from "./DBXAccessToken";
import data from "./Data";

const motpwaApp = combineReducers({
  orgBuffers,
  // nav,
  navStack,
  settings,
  dbxAccessToken,
  data
});

export default motpwaApp;
