import React, { useState } from "react";
import ReactDOM from "react-dom";
// import {
//   SortableContainer,
//   SortableElement,
//   SortableHandle
// } from "react-sortable-hoc";
import arrayMove from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DragHandleIcon from "@material-ui/icons/DragHandle";

import { sortableContainer, sortableElement } from "react-sortable-hoc";
// const DragHandle = SortableHandle(() => (
//   <ListItemIcon>
//     <DragHandleIcon />
//   </ListItemIcon>
// ));

// const SortableItem = SortableElement(({ text }) => (
//   <ListItem ContainerComponent="div">
//     <ListItemText primary={text} />
//     <ListItemSecondaryAction>
//       <DragHandle />
//     </ListItemSecondaryAction>
//   </ListItem>
// ));

// const SortableListContainer = SortableContainer(({ items }) => (
//   <List component="div">
//     {items.map(({ id, text }, index) => (
//       <SortableItem key={id} index={index} text={text} />
//     ))}
//   </List>
// ));
const SortableItem = sortableElement(({ value }) => <li>{value}</li>);
const SortableContainer = sortableContainer(({ children }) => {
  return <ul>{children}</ul>;
});

class SortableList extends React.Component {
  render() {
    const { data, renderItem } = this.props;
    console.log(data);

    let items = data.map((d, idx) => (
      <SortableItem key={d[0]} index={idx} value={renderItem(d, idx)} />
    ));

    const onSortEnd = ({ oldIndex, newIndex }) => {
      // setItems(items => arrayMove(items, oldIndex, newIndex));
      console.log(oldIndex, newIndex);
    };
    return (
      // <SortableListContainer
      //   items={items}
      //   onSortEnd={onSortEnd}
      //   useDragHandle={true}
      //   lockAxis="y"
      // />
      <SortableContainer onSortEnd={onSortEnd} component="div">
        {items}
      </SortableContainer>
    );
  }
}

export default SortableList;
