import React from "react";
import { connect } from "react-redux";

import OrgTimestampUtil from "../../utilities/OrgTimestampUtil";

import HabitGridRow from "./HabitGridRow";

import {
  // getAllNodes,
  // getFlattenedBufferObj,
  getAllHabits, //
  getHabitMatrix
} from "../../selectors";

class HabitGrid extends React.Component {
  constructor(props) {
    super(props);

    const windowWidth = window.innerWidth; //> 0 ? window.innerWidth : screen.width;
    const rs = 5;//13;//21;
    const re = 3;//7;//13;
    const rt = rs + re + 1;
    const blockWPerc = Math.round(5000 / rt) / 100;
    const blockWidth = windowWidth * (blockWPerc / 100);

    this.state = {
      isBeingTouched: false,
      cx: 0,

      blockWPerc,
      blockWidth,
      rs,
      re
    };

    this.gridRef = React.createRef();
  }

  handleScrollStart(cx) {
    this.setState({ isBeingTouched: true, cx  });
  }

  handleScrollMove(newCx) {
    const magic = 3.14;
    const { isBeingTouched, cx, blockWidth } = this.state;
    const { /*date,*/ onIncr, onDecr } = this.props;
    // const foo = OrgTimestampUtil.diff(OrgTimestampUtil.today(), date, "d");
    if (isBeingTouched) {
      const dx = newCx - cx;
      // console.log(this.gridRef.current.scrollWidth);
      // console.log(this.gridRef.current.style.clientWidth);

      if (dx > blockWidth * magic) {
        this.setState({ cx: cx + blockWidth * magic });
        onDecr();
        // this.gridRef.current.style.left = foo * blockWidth + "px";
      } else if (dx < -blockWidth) {
        this.setState({ cx: cx - blockWidth * magic });
        onIncr();
        // this.gridRef.current.style.left = foo * blockWidth + "px";
      }
    }
  }

  handleScrollEnd(){
    this.setState({ isBeingTouched: false });
  }

  handleTouchStart(e) {
    const touch = e.changedTouches[0];
    this.handleScrollStart(touch.clientX);
  }

  handleTouchMove(e) {
    this.handleScrollMove(e.changedTouches[0].clientX);
  }

  handleTouchEnd() {
    this.handleScrollEnd();
  }

  handleMouseDown(e) {
    this.handleScrollStart(e.clientX);
  }

  handleMouseMove(e) {
    // console.log("MOUSE MOVE");
    // console.log(e);
    this.handleScrollMove(e.clientX);
  }

  handleMouseUp() {
    // console.log("MOUSE UP");
    this.handleScrollEnd();
  }

  handleMouseLeave() {
    // console.log("MOUSE LEAVE");
    this.handleScrollEnd();
  }

  render() {
    const {
      date,
      habits // habitData
    } = this.props;
    const { rs, re, blockWPerc, blockWidth } = this.state;

    const startOfDate = OrgTimestampUtil.clone(date);
    startOfDate.time.hh = 0;
    startOfDate.time.mm = 0;
    const offsetD = OrgTimestampUtil.diff(
      OrgTimestampUtil.today(),
      startOfDate,
      "d"
    );
    // console.log("OFFSED D -- ", offsetD, startOfDate);
    // console.log(habits);

    // const center = Math.ceil(habitData[0].length / 2) - offsetD;
    // const left = center - 14;
    // const right = center + 7;
    // const displayHabitData = habitData.map(hd => hd.slice(left, right));

    return (
      <div
        style={{
          width: "50vw",
          overflow: "hidden"
        }}
        onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
        onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
        onTouchEnd={() => this.handleTouchEnd()}
        onMouseDown={this.handleMouseDown.bind(this)}
        onMouseMove={this.handleMouseMove.bind(this)}
        onMouseUp={this.handleMouseUp.bind(this)}
        onMouseLeave={this.handleMouseLeave.bind(this)}
      >
        <div
          style={{
            position: "absolute",
            backgroundColor: "#000",
            opacity: 0.5,
            height: "50%",
            right: 0,
            width: re * blockWidth,
            zIndex: 1
          }}
        />

        <div
          style={{
            position: "absolute",
            backgroundColor: "yellow",
            opacity: 0.5,
            height: "50%",
            right: re * blockWidth + Math.floor(blockWidth / 2),
            width: 1,
            zIndex: 1
          }}
        />

        <div
          ref={this.gridRef}
          style={{
            position: "relative",
            left: 0.5 //-offset + offsetD * blockWidth
          }}
        >
          {habits.map((h, idx) => (
            <HabitGridRow
              key={h.id}
              date={date}
              /* data={displayHabitData[idx]} */
              habitId={h.id}
              blockWidth={blockWPerc + "vw"}
            rangeStart={rs}            
            rangeEnd={re}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const habits = getAllHabits(state);
  // const habitData = getHabitMatrix(state);
  return {
    habits
  };
};

export default connect(
  mapStateToProps,
  null
)(HabitGrid);
