import React from "react";
import { connect } from "react-redux";
import { addNewNode, cycleNodeCollapse, deleteNode } from "../actions";

// import { NavigationActions } from 'react-navigation';

// import {
//   ActionSheetIOS,
//   Button,
//   ScrollView,
//   Text,
//   TouchableHighlight,
//   View
// } from 'react-native';

// import Ionicons from 'react-native-vector-icons/Ionicons';

import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import OrgHeadlineEditable from "../components/OrgHeadlineEditable";
import OrgList from "../components/OrgList";
import SplitPane from "../components/SplitPane";
import OrgSection from "../components/OrgSection";

import { findBranch } from "../utilities/utils";

import { getNode } from "../selectors";

// import appStyles from "../styles";

import Button from "@material-ui/core/Button";

class NodeDetailScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childListIsLocked: true
    };
  }
  render() {
    const { goBack } = this.props;

    const {
      tree,
      bufferID,
      nodeID,
      node,
      onPressDeleteNode,
      onAddOnePress,
      isNew
    } = this.props;

    const children = findBranch(tree, nodeID).children;
    let list = null;
    if (children && children.length > 0) {
      list = (
        <div
          style={{
            overflowX: "hidden",
            overflowY: "scroll",
            flex: 1,
            margin: 10,
            marginBottom: 40
          }}
        >
          <OrgList
            data={children}
            bufferID={bufferID}
            isLocked={this.state.childListIsLocked}
          />
        </div>
      );
    }

    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100vh",
          backgroundColor: "#334851",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
          WebkitOverflowScrolling: "touch",
          zIndex: 20
        }}
      >
        {/* <div> */}
        <OrgHeadlineEditable
          bufferID={bufferID}
          nodeID={nodeID}
          autoFocus={isNew}
        />
        {/* </div> */}
        <div
          style={{
            flex: 1,
            display: "flex",
            overflow: "hidden",
            flexDirection: "column"
          }}
        >
          <SplitPane
            /* style={{ display: "flex", flexDirection: "column" }} */
            viewA={
              /* <div */
              /*   style={{ */
              /*     display: "flex", */
              /*     flexDirection: "row", */
              /*     backgroundColor: "#cccccc" */
              /*   }} */
              /* > */
              <OrgSection bufferID={bufferID} nodeID={nodeID} />
              /* </div> */
            }
            viewB={[
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#cccccc"
                }}
                onClick={() => {
                  this.setState({
                    childListIsLocked: !this.state.childListIsLocked
                  });
                }}
              >
                {this.state.childListIsLocked ? (
                  <LockIcon fontSize="small" style={{ marginLeft: 5 }} />
                ) : (
                  <LockOpenIcon fontSize="small" style={{ marginLeft: 5 }} />
                )}
                {!this.state.childListIsLocked && (
                  <Button
                    onClick={() => {
                      onAddOnePress(bufferID, nodeID, node);
                    }}
                    /* color="#841584" */
                    /* accessibilityLabel="add child node" */
                  >
                    Add Child
                  </Button>
                )}
              </div>,
              list
            ]}
          />
        </div>
        <Button onClick={goBack}>Back</Button>
      </div>
    );
  }
}

////////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state, ownProps) => {
  console.log("OWN PROPS ---- ", state);
  const { navStack } = state;
  const { params } = navStack[navStack.length - 1];
  // const params = ownProps.navigation.state.params;
  const { bufferID, nodeID, isNew } = params;
  const tree = state.orgBuffers[bufferID].orgTree;
  const node = getNode(state, { bufferID, nodeID });
  return {
    bufferID,
    nodeID,
    node,
    tree,
    isNew
  };
};

const mapDispatchToProps = dispatch => {
  return {
    goBack: () => {
      dispatch({
        type: "NavStack:Pop"
      });
    },
    // onNodeArrowClick: bufferID => {
    //   return nodeID => {
    //     dispatch(cycleNodeCollapse(bufferID, nodeID));
    //   };
    // },
    // onNodeTitleClick: bufferID => {
    //   return nodeID => {
    //     dispatch(
    //       NavigationActions.navigate({
    //         routeName: "NodeDetail",
    //         params: { bufferID, nodeID }
    //       })
    //     );
    //   };
    // },
    // onPressDeleteNode: (bufferID, nodeID) => {
    //   dispatch(deleteNode(bufferID, nodeID));
    // },
    onAddOnePress: (bufferID, nodeID, node) => {
      console.log(bufferID, nodeID, node);
      dispatch(addNewNode(bufferID, nodeID, node.stars + 1));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NodeDetailScreen);
