import React, { Component } from "react";
// import { Text, div, View } from "react-native";
// import Ionicons from "react-native-vector-icons/Ionicons";
// import Swipeout from "react-native-swipeout";
import SwipeableViews from "react-swipeable-views";

import KitchenIcon from "@material-ui/icons/Kitchen";
import KitchenTwoToneIcon from "@material-ui/icons/KitchenTwoTone";

import OrgDrawerItem from "./OrgDrawerItem";

class OrgDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: this.props.isCollapsed,
      editIdx: null
    };
  }

  _toggleCollapse() {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  render() {
    if (this.state.isCollapsed) {
      return (
        <div onClick={this._toggleCollapse.bind(this)}>
          <div
            className="OrgDrawer"
            style={{
              flexDirection: "row",
              backgroundColor: "#cccccc"
            }}
          >
            <KitchenIcon fontSize="small" style={{ marginLeft: 5 }} />
          </div>
        </div>
      );
    } else {
      const listItems =
        this.props.drawer && this.props.drawer.props
          ? Object.entries(this.props.drawer.props).map((keyval, idx) => {
              const key = keyval[0];
              const val = keyval[1];
              return (
                <OrgDrawerItem
                  key={idx}
                  idx={idx}
                  propKey={key}
                  propVal={typeof val === "object" ? val.value : val}
                  onRemoveProp={this.props.onRemoveProp}
                  onUpdateProp={this.props.onUpdateProp}
                  onItemEditBegin={itemIdx => {
                    this.setState({ editIdx: itemIdx });
                  }}
                  onItemEditEnd={() => {
                    this.setState({ editIdx: null });
                  }}
                  disabled={
                    this.state.editIdx === null || this.state.editIdx === idx
                      ? false
                      : true
                  }
                />
              );
            })
          : [];

      return (
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>

          {/* <Swipeout */}
          {/*   autoClose={true} */}
          {/*   left={[ */}
          {/*     { */}
          {/*       text: "addOne", */}
          {/*       backgroundColor: "#33bb33", */}
          {/*       onClick: () => { */}
          {/*         this.props.onAddProp(); */}
          {/*       } */}
          {/*     } */}
          {/*   ]} */}
          {/* > */}
          <div onClick={this._toggleCollapse.bind(this)}>
            <div
              className="OrgDrawer"
              style={{
                flexDirection: "row",
                backgroundColor: "#cccccc"
              }}
            >
              <KitchenTwoToneIcon fontSize="small" style={{ marginLeft: 5 }} />
            </div>
          </div>
          <SwipeableViews enableMouseEvents={true}>
          <div style={{ width: 50 }}>...</div>
          <div
        style={{ width: 50, backgroundColor: "#33bb33" }}
        onClick={() => {
          this.props.onAddProp();
        }}
          >
          addOne
        </div>
          </SwipeableViews>
          {/* </Swipeout> */}
          <div className="OrgDrawerProperties">{listItems}</div>
        </div>
      );
    }
  }
}

export default OrgDrawer;
