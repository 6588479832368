import React from "react";

import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";

import OKCancel from "../OKCancel.js";

const OKEditCancelDialog = ({ visible, onEditDateClick, onOk, onCancel }) => (
  <Dialog open={visible}>
    <div style={{ flex: 1 }}>
      <Button onClick={onEditDateClick}>Edit Date</Button>
    </div>
    <OKCancel onOk={onOk} onCancel={onCancel} />
  </Dialog>
);

export default OKEditCancelDialog;
