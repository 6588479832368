import React, { Component } from "react";
import { connect } from "react-redux";
// import { StyleSheet, Text, TouchableHighlight, Box } from "react-native";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";

import * as R from "ramda";

import OrgHeadline from "./OrgHeadlineToo";
import List from "./List";
import Tree from "./Tree";

export default class OrgList extends Component {
  // constructor(props){
  //   super(props);
  //   this.state = {
  //     selectedNodeID: null
  //   }
  // }

  render() {
    const {data, bufferID, isLocked, selectedNodeID, onNodeSelect} = this.props;

    const getGetHasKids = idx => (path, cbk) => {
      const lens = R.lensPath(path);
      const branch = R.view(lens, data[idx]);
      cbk(branch.children && branch.children.length > 0);
    };

    const getGetItems = idx => (path, cbk) => {
      const lens = R.lensPath(path);
      const branch = R.view(lens, data[idx]);
      cbk(
        branch.children.map((c, idx2) => {
          const newPath = path.slice(0);
          newPath.push("children");
          newPath.push(idx2);
          return {
            title: c.id,
            path: newPath,
            type: c.children && c.children.length > 0 ? "branch" : "leaf"
          };
        })
      );
    };
    
    const getRenderLeafItem = idx => (title, path, type, hasKids) => (
      <div
          style={{
              marginLeft: 8 * (path.length / 1)
          }}
        key={data[idx].id}
      >
        <OrgHeadline
          bufferID={bufferID}
          nodeID={title}
          isLocked={isLocked}
          selected={selectedNodeID === title}
          onSelect={onNodeSelect}
        />
      </div>
    );

    const getRenderBranchItem = idx => (
      title,
      path,
      type,
      hasKids,
      isCollapsed,
      onToggleCollapse
    ) => {
      const lens = R.lensPath(path);
      const branch = R.view(lens, data[idx]);
      let pref;
      let textStyle = { fontWeight: "bold" };
      if (hasKids) {
        if (isCollapsed) {
          pref = "⤷";
        } else {
          pref = "↓";
        }
      } else {
        pref = "⇢";
      }
      return (
        <div key={title} style={{ flexDirection: "row", display: "flex" }}>
          <div
            onClick={onToggleCollapse}
            style={{
                marginLeft: - 11 +  (path.length * 8)//8 * (path.length / 1) - 11
            }}
          >
            <Typography variant="body2" style={{width:11, ...textStyle}}>
              {pref}
            </Typography>
          </div>
          <div style={{ flex: 1 }}>
            <OrgHeadline
              bufferID={bufferID}
              nodeID={branch.id}
              isLocked={isLocked}
              selected={selectedNodeID === branch.id}
              onSelect={onNodeSelect}
            />
          </div>
        </div>
      );
    };

    const renderListItem = (d, idx) => {
      if (d.children && d.children.length > 0) {
        return (
          <Tree
            key={d.id}
            title={d.id}
            path={[]}
            type={"branch"}
            getHasKids={getGetHasKids(idx)}
            getItems={getGetItems(idx)}
            renderLeafItem={getRenderLeafItem(idx)}
            renderBranchItem={getRenderBranchItem(idx)}
          />
        );
      } else {
        //style={{ paddingLeft: 11 }}
        return (
            <OrgHeadline
              key={d.id}
              bufferID={bufferID}
              nodeID={d.id}
              isLocked={isLocked}
              selected={selectedNodeID === d.id}
              onSelect={onNodeSelect}
            />
        );
      }
    };

    return <List data={data} renderItem={renderListItem} />;
  }
}
