import React, { Component } from "react";
import { connect } from "react-redux";

import AlarmIcon from "@material-ui/icons/Alarm";

import {
  updateNodeTimestamp,
  updateNodeTimestampRepInt,
  clearNodeTimestamp
} from "../actions";

import OrgTimestamp from "./OrgTimestamp";

import OrgTimestampUtil from "../utilities/OrgTimestampUtil";
import OrgNodeUtil from "../utilities/OrgNodeUtil";

import { getNode } from "../selectors";

const timestampTypes = ["OPENED", "SCHEDULED", "DEADLINE", "CLOSED"];

class OrgPlanning extends Component {
  constructor(props) {
    super(props);
    this.state = { isCollapsed: this.props.isCollapsed };
  }

  _toggleCollapse() {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  render() {
    if (this.state.isCollapsed) {
      return (
        <div
          onClick={this._toggleCollapse.bind(this)}
          className="OrgPlanning"
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#cccccc"
          }}
        >
          <AlarmIcon fontSize="small" />
        </div>
      );
    } else {
      const {
        bufferID,
        nodeID,
        node,
        onTimestampUpdate,
        onTimestampRepIntUpdate,
        onTimestampClear
      } = this.props;
      const planning = OrgNodeUtil.getPlanning(node);
      const timings = timestampTypes.map(t => (
        <OrgTimestamp
          key={t}
          onTimestampUpdate={onTimestampUpdate(bufferID, nodeID, t)}
          onTimestampRepIntUpdate={onTimestampRepIntUpdate(bufferID, nodeID, t)}
          onTimestampClear={onTimestampClear(bufferID, nodeID, t)}
          timestamp={planning ? planning[t.toLowerCase()] : null}
          label={t}
        />
      ));

      return (
        <div /* style={appStyles.container} */>
          <div
            onClick={this._toggleCollapse.bind(this)}
            className="OrgPlanning"
            style={{
              flexDirection: "row",
              backgroundColor: "#cccccc"
            }}
          >
            <AlarmIcon fontSize="small" style={{ marginLeft: 5 }} />
          </div>
          <div>{timings}</div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { bufferID, nodeID } = ownProps;
  const node = getNode(state, ownProps);
  return {
    bufferID,
    nodeID,
    node
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTimestampUpdate: (bufferID, nodeID, timestampType) => date => {
      let timestamp = OrgTimestampUtil.parseDate(date);
      timestamp.type = "org.timestamp.active";
      timestamp = OrgTimestampUtil.updateValue(timestamp);
      dispatch(updateNodeTimestamp(bufferID, nodeID, timestampType, timestamp));
    },
    onTimestampRepIntUpdate: (bufferID, nodeID, timestampType) => (
      repInt,
      repMin,
      repMax
    ) => {
      dispatch(
        updateNodeTimestampRepInt(
          bufferID,
          nodeID,
          timestampType,
          repInt,
          repMin,
          repMax
        )
      );
    },
    onTimestampClear: (bufferID, nodeID, timestampType) => () => {
      dispatch(clearNodeTimestamp(bufferID, nodeID, timestampType));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgPlanning);
