import React from "react";
import { connect } from "react-redux";
import List from "../components/List";
import Tree from "../components/Tree";
import * as R from "ramda";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

import { BASE_URI } from "../constants";
import {
  removeBuffer,
  updateBaseDirSetting,
  updateInboxPathSetting,
  loadOrgFiles,
  saveSettingsToStorage
} from "../actions";
import DropboxDataSource from "../utilities/DropboxDataSource";
import {
  dbxAuthorize,
  dbxDeauthorize,
  accessTokenIsValid
} from "../utilities/DropboxAuth";

class FileNameInputList extends React.Component {
  render() {
    console.log("RENDER FILENAME INPUT LIST")
    const { dbxds, orgFiles, onNodeCheckPress } = this.props;
    return (
      <div style={{ borderColor: "#000", borderWidth: 1, textAlign: "left", overflowY: "auto" , maxHeight: "50vh"}}>
        <Tree
          title={"home"}
          path={""}
          type={"branch"}
          getHasKids={(path, cbk) => {
            dbxds
              .filesListFolderAsync(path)
              .then(res => {
                cbk(res.entries.length > 0);
              })
              .catch(err => {
                /* console.log(err); */
              });
          }}
          getItems={(path, cbk) => {
            dbxds.filesListFolderAsync(path).then(res => {
              cbk(
                res.entries.map(r => {
                  return {
                    title: r.name,
                    path: r.path_lower,
                    type: r[".tag"] === "folder" ? "branch" : "leaf"
                  };
                })
              );
            });
          }}
          renderLeafItem={(title, path, type, hasKids) => {
            const isChecked = R.contains(path, orgFiles);
            const selectable = path.endsWith(".org");
            return (
              <div
                style={{
                  flexDirection: "row",
                  marginLeft: 10 * path.split("/").length
                }}
              >
                <Checkbox
                  checked={isChecked}
                  style={selectable ? {} : { opacity: 0.25 }}
                  onChange={() => {
                    if (selectable) onNodeCheckPress(path, orgFiles, dbxds);
                  }}
                />
                <span style={{ flex: 1 }}>{title}</span>
              </div>
            );
          }}
          renderBranchItem={(
            title,
            path,
            type,
            hasKids,
            isCollapsed,
            onToggleCollapse
          ) => {
            let pref;
            let textStyle = { fontWeight: "bold" };
            if (hasKids) {
              if (isCollapsed) {
                pref = "⤷";
              } else {
                pref = "↓";
              }
            } else {
              pref = "⇢";
            }
            return (
              <div
                style={{ marginLeft: 10 * path.split("/").length }}
                onClick={onToggleCollapse}
              >
                <span style={textStyle}>{pref + " " + title}</span>
              </div>
            );
          }}
        />
      </div>
    );
  }
}

class SettingsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "Useless Placeholder"
    };
  }

  componentDidMount() {
    if (this.props.baseDir === undefined) {
    } else {
      this.setState({ text: this.props.baseDir.path });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.baseDir && nextProps.baseDir.path) {
      this.setState({
        text: nextProps.baseDir.path,
        isOk: nextProps.baseDir.isOk
      });
    }
  }

  render() {
    const { text } = this.state;
    const {
      accessToken,
      tryUpdateBaseDir,
      baseDir,
      data,
      dbxds,
      orgFiles,
      onNodeCheckPress
    } = this.props;

    const refreshButton = (
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          console.log("refersh");
          window.location = BASE_URI;
        }}
        aria-label="refresh"
      >
        Refresh
        <RefreshIcon />
      </Button>
    );

    const optA = (
      <Grid container direction="column" justify="center" alignItems="center">
        <Box>
          <Button size="small" variant="contained" onClick={dbxAuthorize}>
            connect to dropbox
          </Button>
        </Box>
        <Box>{refreshButton}</Box>
      </Grid>
    );

    const optB = (
      <Grid container direction="column" justify="center" alignItems="center">
        <Box>
          <Button size="small" variant="contained" onClick={dbxDeauthorize}>
            logout
          </Button>
        </Box>
        <Box>
          <TextField
            value={text}
            onChange={e => this.setState({ text: e.target.value })}
            onBlur={() => tryUpdateBaseDir(text)}
            /* onEndEditing={() => this.props.onEndEditing(this.state.text)} */
          />
        </Box>
        <Box>{refreshButton}</Box>
      </Grid>
    );
    console.log("DATA !!!!", data);

    return (
      <div>
        {data ? (
          /* <List */
          /*   data={data} */
          /*   renderItem={d => <div key={d.name}>{d.name}</div>} */
          /* /> */
          <FileNameInputList
            dbxds={dbxds}
            orgFiles={orgFiles}
            onNodeCheckPress={onNodeCheckPress}
            files={this.props.orgFiles}
          />
        ) : null}
        <div>{accessToken ? optB : optA}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //   /* if (ownProps.screenProps.currRoute !== 'SettingsTab') return {};*/
  //   const bufferEntry = Object.entries(state.orgBuffers).find(([k, v]) => {
  //     return k.endsWith('settings.org');
  //   });
  //   const bufferID = bufferEntry[0];
  //   const settings = bufferEntry[1].orgTree;
  const dbxds = new DropboxDataSource({
    accessToken: state.dbxAccessToken
  });

  console.log(state);
  return {
    //     inboxFile: state.settings.inboxFile,
    //     ledgerFile: state.settings.ledgerFile,
    orgFiles: state.settings.orgFiles,
    dbxds,
    //     bufferID,
    //     settings
    baseDir: state.settings.baseDir,
    accessToken: state.dbxAccessToken
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    tryUpdateInboxFilepath: path => {
      dispatch(updateInboxPathSetting(path));
    },

    tryUpdateBaseDir: path => {
      dispatch(updateBaseDirSetting(path));
    },
    // tryUpdateLedgerFile: path => {
    //   dispatch(someActionThree(path));
    // },
    // onClearInboxPress: () => {
    //   dispatch(clearInboxFile());
    // },
    // onClearLedgerPress: () => {
    //   dispatch(clearLedgerFile());
    // },
    onNodeCheckPress: (path, orgFiles) => {
      dispatch({ type: "settings:toggleOrgFile", path });
      if (R.contains(path, orgFiles)) {
        dispatch(removeBuffer(path));
      } else {
        dispatch(loadOrgFiles([path]));
      }
      dispatch(saveSettingsToStorage());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsScreen);
