import { createSelector } from "reselect";
import * as R from "ramda";

import OrgNodeUtil from "../utilities/OrgNodeUtil";
import OrgTimestampUtil from "../utilities/OrgTimestampUtil";
import { OrgTimestamp } from "org-parse";

const filterHabits = nds => {
  return nds.filter(n => {
    const propDrawer = OrgNodeUtil.getPropDrawer(n);
    if (propDrawer && propDrawer.props.STYLE === "habit") return true;
    return false;
  });
};

////////////////////////////////////////////////////////////////////////////////

const getBuffers = state => {
  return state.orgBuffers;
};

const getNav = state => state.nav;
const getSettings = state => state.settings;

export const getFlattenedBufferObj = state => {
  // given org.document tree, returns flattened object where keys are node ids
  // and values are org-node objects
  let obj = {};
  const kids = state.headlines || state.children;

  if (state.type !== "org.document") obj[state.id] = state;

  if (kids) {
    obj = kids.reduce(
      (m, v) => Object.assign({}, m, getFlattenedBufferObj(v)),
      obj
    );
  }

  return obj;
};

//----------------------------====================================================

export const getNode = (state, { bufferID, nodeID }) => {
  return getFlattenedBufferObj(state.orgBuffers[bufferID].orgTree)[nodeID];
};
export const getAllNodes = createSelector(
  [getBuffers],
  buffers => {
    return R.reduce(
      (m, bufferObj) =>
        R.concat(
          R.reduce(
            (m2, nodeObj) => R.insert(m2.length, nodeObj, m2),
            [],
            Object.values(getFlattenedBufferObj(bufferObj[1].orgTree))
          ),
          m
        ),
      [],
      Object.entries(buffers)
    );
  }
);

export const getAllHabits = createSelector(
  [getAllNodes],
  nodes => {
    return filterHabits(nodes);
  }
);

export const getHabitLogs = createSelector(
  [getAllHabits],
  habits => {
    const logs = habits.map(n => {
      const logbook = OrgNodeUtil.getLogbook(n);
      if (logbook) {
        // don't know why there'd be no logbook if passed previous
        // filter...maybe if completely new habit but not yet logged done in
        // other words this needs to be caught much earlier...i.e. around the
        // time the orgfile is parsed to begin with
        let logData = logbook.items.filter(
          le =>
            le.type === "state" && le.state === '"DONE"' && le.from === '"TODO"'
        );

        logData = logData.sort((a, b) =>
          OrgTimestampUtil.compare(a.timestamp, b.timestamp)
        );

        return [n.id, logData];
      }
      return [n.id, []];
    });
    return logs;
  }
);

const getDate = (state, props) => {
  return props.date;
};

export const getNodeHabitLogs = createSelector(
  [getNode, getDate],
  (node, date) => {
    const dateClone = OrgTimestampUtil.clone(date);
    dateClone.time.hh = 0;
    dateClone.time.mm = 0;
    const ds = OrgTimestampUtil.sub(dateClone, { days: 13 });
    const de = OrgTimestampUtil.add(dateClone, { days: 8 });
    const logbook = OrgNodeUtil.getLogbook(node);
    if (logbook) {
      let logData = logbook.items.filter(
        le =>
          le.type === "state" && le.state === '"DONE"' && le.from === '"TODO"'
      );

      logData = logData.sort((a, b) =>
        OrgTimestampUtil.compare(a.timestamp, b.timestamp)
      );

      // let idxs;
      // let idxe;
      // let retData = [];
      // for (var i = 0; i < logData.length; i++) {
      //   const curr = logData[i];
      //   const currTS = curr.timestamp;

      //   const compPast = OrgTimestampUtil.compare(ds, currTS);
      //   const compFut = OrgTimestampUtil.compare(de, currTS);

      //   if (compPast < 0 && compFut > 0) {
      //     retData.push(curr);
      //   }

      //   // const thisOne = logData[i]
      //   // const nextOne = logData[i + 1]
      //   // if(there is no nextOne){
      //   //   //do some shit
      //   // }else{
      //   //   const comp = OrgTimestampUtil.compare(thisOne, nextOne)
      //   // }
      // }

      return logData;//retData;
    } else {
      return [];
    }
  }
);

export const makeGetHabitMatrixRow = (id, rs, re) =>
  createSelector(
    [getNode, getNodeHabitLogs, getDate],
    (node, logs, date) => {
      const dateClone = OrgTimestampUtil.clone(date);
      dateClone.time.hh = 0;
      dateClone.time.mm = 0;
      const ds = OrgTimestampUtil.sub(dateClone, { days: rs });
      const de = OrgTimestampUtil.add(dateClone, { days: re });
      const rt = rs + 1 + re;
      const logbook = OrgNodeUtil.getLogbook(node);
      let ret = [];
      if (logs) {

        const timingKey = {d:1,
                           w:7};
        const scheduled = OrgNodeUtil.getScheduled(node);
        const repMin = OrgTimestampUtil.getRepMin(scheduled);
        const repMax = OrgTimestampUtil.getRepMax(scheduled);
        const repMinVal = parseInt(repMin.substr(0, repMin.length - 1));
        const repMinU = repMin[repMin.length - 1];
        const repMaxVal = repMax
              ? parseInt(repMax.substr(0, repMax.length - 1))
        : repMinVal;
        const repMaxU = repMax ? repMax[repMax.length - 1] : repMinU;
        const repMinD = repMinVal * timingKey[repMinU];
        const repMaxD = repMaxVal * timingKey[repMaxU];

        let logsF = logs.filter((log, idx)=>{
          let a, b;
          // if(idx === 0){
          //   a = log
          // }else{
          //   a = logs[idx - 1]
          // }

          if(idx === (logs.length -1)){
            b = log
          }else{
            b = logs[idx + 1]
          }

          // const compa = -2 <
          //       OrgTimestampUtil.compare(a.timestamp, ds) +
          //       OrgTimestampUtil.compare(a.timestamp, de);
          const compLog = -2 <
                OrgTimestampUtil.compare(log.timestamp, ds) +
                OrgTimestampUtil.compare(log.timestamp, de);
          const compb = -2 <
                OrgTimestampUtil.compare(b.timestamp, ds) +
                OrgTimestampUtil.compare(b.timestamp, de);
          // // if(idx === 0){
          // // }else
          
          return /*compa ||*/ compLog || compb;
        })

        if(logsF.length){
          console.log('do calculations')
          // let reticle = []
          // for(let i = logsF.length - 1; i >= 0; i--){
          //   let dummy;
          //   if(i === logsF.length - 1){
          //     dummy = Math.abs(OrgTimestampUtil.diff(de, logsF[i].timestamp, "days"));
          //     reticle = reticle.concat(new Array(dummy).fill({status:"-", done: false}))
          //   }else{
          //     dummy = Math.abs(OrgTimestampUtil.diff(ds, logsF[i].timestamp, "days"));
          //     reticle = reticle.concat(new Array(dummy).fill({status:"blank", done: false}))
          //   }
          // }
          let nextLE = logsF[0];
          let nextLETS = OrgTimestampUtil.beginOfDay(nextLE.timestamp)
          let s = {status: 'blank', done: false}
          let r = [];

          if(OrgTimestampUtil.compare(nextLETS, ds) < 0){
            // const nextLE = logsF[0]
            // if(nextLE){
            const diff = OrgTimestampUtil.diff(ds, nextLETS, 'days');
            if(diff > repMaxVal){
              s.status = '-';
            }else{
              console.log('figure on current state')
            }
            // }
            logsF = logsF.slice(1);
            nextLE = logsF[0];
            nextLETS = OrgTimestampUtil.beginOfDay(nextLE.timestamp)
          }

          for(let i = 0; i < rt; i++){
            const di = OrgTimestampUtil.add(ds, {days: i})
            const diff = OrgTimestampUtil.compare(di, nextLETS);
            console.log(logsF.slice(0), di.value, nextLETS && nextLETS.value, diff)

            if(diff < 0){
              r.push(Object.assign({}, s))
            }else if(diff === 0){
              r.push(Object.assign({}, s, {done: true}))
              logsF = logsF.slice(1);
              nextLE = logsF[0];
              nextLETS = nextLE && OrgTimestampUtil.beginOfDay(nextLE.timestamp)
              s.status= repMaxVal === 1 ? 'y' : 'b';
            }else if(diff > 0){
              r.push(Object.assign({}, s))
            }
            // console.log(ds.value, di.value, OrgTimestampUtil.compare(dateClone, di))
            // console.log(i)
            // console.log(nextLE.timestamp.value)
            // console.log(OrgTimestampUtil.beginOfDay(nextLE.timestamp).value)
            // console.log(nextLE.timestamp.value, di.value, OrgTimestampUtil.compare(nextLE.timestamp, di))
            // if(i === 0){
            //   const isAfterStartOfRange = OrgTimestampUtil.compare(ds, di)
            //   if()
            // }
          }
          // return new Array(rt).fill({status:"blank", done:false})
          return r
        }else{
          // const last = logs.slice(-1)[0];
          // if(last){
          //   if(repMaxVal< OrgTimestampUtil.diff(dateClone, last.timestamp, "days")){
          //     return new Array(rt).fill({status:"-", done: false})
          //   }else{
          //     console.log("gonna have to figure out multiple slices")
          //   }

          // }else{
            return new Array(rt).fill({status:"blank", done:false})
          // }
        }


        const logIdx = logs.findIndex((l)=>{
          return OrgTimestampUtil.compare( l.timestamp, ds ) > 0
        })

        if(logIdx === -1){
          // no date exists after start of range
          return new Array(rt).fill({status:"-", done:false})
        }else{
          // found a date after start of range
          const logItem = logs[logIdx]
          const tsFoo = logItem.timestamp
          const toBeFilled = OrgTimestampUtil.diff(tsFoo, ds, "days")
          if(logIdx > 0){
            // if a previous date exists before start of range
            const tsBar = logs[logIdx - 1].timestamp
            const daysSincePrev = OrgTimestampUtil.diff(tsFoo, tsBar, "days")
            let filledWith;
            if(daysSincePrev > repMaxVal){
              //assuming repMaxU === 'd'
              filledWith = '-';
            }else{
              filledWith = 'b'
            }

            // console.log(toBeFilled)
            // console.log(daysSincePrev, repMaxVal, repMaxU)
            // console.log(repMinD, repMaxD)
            const xxx = new Array(toBeFilled).fill({status:filledWith, done:false})
            xxx.push({status:"b", done: true})
            const yyy = new Array(rt - toBeFilled - 1).fill({status:"b", done:false})
            return xxx.concat(yyy)
          }else{
            // no previous date exists before start of range
            const xxx = new Array(toBeFilled).fill({status:'blank', done:false})
            xxx.push({status:"b", done: true})
            const yyy = new Array(rt - toBeFilled - 1).fill({status:"b", done:false})
            return xxx.concat(yyy)
            // return new Array(rt).fill({status:"b", done:false})
          }
        }
        // let i = 0;
        // while(i < rt){
        //   const d = OrgTimestampUtil.add(ds, { days: i });
        //   const curr = logs[0]
        //   if(curr){
        //     const currTS = OrgTimestampUtil.clone(curr.timestamp)
        //     const diffCurr = OrgTimestampUtil.diff(currTS, d, "d");
        //     if(diffCurr===0){
        //       const next = logs[1];
        //       const nextTS = next && OrgTimestampUtil.clone(next.timestamp)
        //       const diffNext = nextTS && OrgTimestampUtil.diff(nextTS, d, "d");
        //       ret.push({status:"g", done:true});
        //       logs.shift();
        //       console.log(diffNext, repMaxD, repMinD)
        //       if(diffNext && ((repMaxD && diffNext > repMaxD) || (repMinD && diffNext > repMinD))){
        //         console.log('diffNext > repMaxD');
        //         [i, ret] = addRangeMin(i, ret, repMinD)
        //       }else if(diffNext && diffNext < repMaxD && diffNext > repMinD){
        //         console.log('diffNext < repMaxD && > repMinD')
                
        //       }else if(diffNext && diffNext < repMinD){
        //         console.log('diffNext < repMinD ')
        //       }else{
        //         // there is no diffNext
        //         [i, ret] = addRangeMin(i, ret, repMinD)
        //         // [i, ret] = finishItFromHere(i, ret);
        //       }
        //       i++;
        //     }else if(diffCurr > 0){
        //       const range = new Array(diffCurr).fill({status:"-", done: false})
        //       ret = ret.concat(range)
        //       i += diffCurr;
        //     }
        //   }else{
        //     [i, ret] = finishItFromHere(i, ret);
        //   }
        // }

        // for (var i = 0; i < rt; i++) {
        //   const d = OrgTimestampUtil.add(ds, { days: i });
        //   const curr = logs[0];
        //   if (curr) {
        //     const currTS = OrgTimestampUtil.clone(curr.timestamp);
        //     currTS.time.hh = 0;
        //     currTS.time.mm = 0;
        //     // console.log(d.date, d.time, currTS.date, currTS.time);
        //     // console.log(OrgTimestampUtil.compare(d, currTS));
        //     if (OrgTimestampUtil.compare(d, currTS) === 0) {
        //       ret.push({ status: "g", done: true });
        //       logs.shift();

        //     } else {
        //       ret.push({ status: "-", done: false });
        //     }
        //   } else {
        //     ret.push({ status: "-", done: false });
        //   }
        // }
      } else {
        ret = new Array(21).fill({ status: "-", done: false });
      }
      return ret;

      //if (logbook) {
      // don't know why there'd be no logbook if passed previous
      // filter...maybe if completely new habit but not yet logged done in
      // other words this needs to be caught much earlier...i.e. around the
      // time the orgfile is parsed to begin with
      //   let logData = logbook.items.filter(
      //     le =>
      //       le.type === "state" && le.state === '"DONE"' && le.from === '"TODO"'
      //   );
      //   logData = logData.sort((a, b) =>
      //     OrgTimestampUtil.compare(a.timestamp, b.timestamp)
      //   );
      //   if (logData.length > 0) {
      //     const times = R.map(l => {
      //       return l.timestamp;
      //     }, logData).sort(OrgTimestampUtil.compare);
      //     const past = OrgTimestampUtil.sub(OrgTimestampUtil.today(), {
      //       days: 11
      //     }); //times[0];
      //     past.time.hh = 0;
      //     past.time.mm = 0;
      //     const firstTime = past;
      //     const lastTime = OrgTimestampUtil.today(); // times[times.length - 1];
      //     const diffDays =
      //       OrgTimestampUtil.diff(lastTime, firstTime, "d") * 2 + 1;
      //     const scheduled = OrgNodeUtil.getScheduled(node);
      //     const repMin = OrgTimestampUtil.getRepMin(scheduled);
      //     const repMax = OrgTimestampUtil.getRepMax(scheduled);
      //     const repMinVal = parseInt(repMin.substr(0, repMin.length - 1));
      //     const repMinU = repMin[repMin.length - 1];
      //     const repMaxVal = repMax
      //       ? parseInt(repMax.substr(0, repMax.length - 1))
      //       : null;
      //     const repMaxU = repMax ? repMax[repMax.length - 1] : null;
      //     let ret = [];
      //     let rngMin = 0;
      //     let rngMax = 0;
      //     for (let i = 0; i < diffDays; i++) {
      //       const curr = OrgTimestampUtil.add(past, { days: 1 * i });
      //       const next = OrgTimestampUtil.add(curr, { days: 1 });
      //       const ts = logData.length > 0 ? logData[0].timestamp : null;
      //       const datum = { status: null, done: false };
      //       if (
      //         ts !== null &&
      //         OrgTimestampUtil.compare(ts, curr) > 0 &&
      //         OrgTimestampUtil.compare(ts, next) < 0
      //       ) {
      //         logData.shift();
      //         datum.done = true;
      //         if (rngMax && rngMax > 0) {
      //           if (rngMin > 0) {
      //             datum.status = "b";
      //           } else {
      //             if (rngMax === 1) {
      //               datum.status = "y";
      //             } else {
      //               datum.status = "g";
      //             }
      //           }
      //         } else if (rngMin > 0) {
      //           if (rngMin === 1) {
      //             datum.status = "g";
      //           } else {
      //             datum.status = "b";
      //           }
      //         } else {
      //           datum.status = "-";
      //         }
      //         rngMin = repMinVal * { d: 1, w: 7 }[repMinU];
      //         rngMax = repMaxVal ? repMaxVal * { d: 1, w: 7 }[repMaxU] : 0;
      //         if (rngMax > 0) rngMin--;
      //       } else if (
      //         OrgTimestampUtil.compare(OrgTimestampUtil.today(), curr) === 0
      //       ) {
      //         if (rngMin > 0) rngMin--;
      //         if (rngMax && rngMax > 0) rngMax--;
      //         datum.status = "!";
      //       } else {
      //         if (rngMax && rngMax > 0) {
      //           if (rngMin > 0) {
      //             datum.status = "b";
      //             rngMin--;
      //           } else {
      //             if (rngMax === 1) {
      //               datum.status = "y";
      //             } else {
      //               datum.status = "g";
      //             }
      //           }
      //           rngMax--;
      //         } else if (rngMin > 0) {
      //           if (rngMin === 1) {
      //             datum.status = "y";
      //           } else {
      //             datum.status = "b";
      //           }
      //           rngMin--;
      //         } else {
      //           datum.status = "-";
      //         }
      //       }
      //       ret.push(datum);
      //     }
      //     return ret;
      //   }
      //   // return new Array(diffDays).fill({ status: "e", done: false });
      //}
      //return [];
      //return new Array(diffDays).fill({ status: "e", done: false });
      // return logs;
      // return nodes[id];
    }
  );

export const getHabitMatrix = createSelector(
  [getAllHabits, getHabitLogs],
  (habits, logs) => {
    console.log("CALCCCCULATING HABIT MATRIX");
    const logDict = R.fromPairs(logs);
    const times = R.filter(([id, l]) => l.length > 0, logs)
      .map(([id, l]) => {
        return l[0].timestamp;
      })
      .sort(OrgTimestampUtil.compare);
    const past = times[0];
    past.time.hh = 0;
    past.time.mm = 0;
    const firstTime = past;
    const lastTime = OrgTimestampUtil.today(); // times[times.length - 1];

    const diffDays = OrgTimestampUtil.diff(lastTime, firstTime, "d") * 2 + 1;

    const trix = habits.map(n => {
      const logData = logDict[n.id];
      if (logData.length > 0) {
        const scheduled = OrgNodeUtil.getScheduled(n);
        const repMin = OrgTimestampUtil.getRepMin(scheduled);
        const repMax = OrgTimestampUtil.getRepMax(scheduled);
        const repMinVal = parseInt(repMin.substr(0, repMin.length - 1));
        const repMinU = repMin[repMin.length - 1];
        const repMaxVal = repMax
          ? parseInt(repMax.substr(0, repMax.length - 1))
          : null;
        const repMaxU = repMax ? repMax[repMax.length - 1] : null;

        let ret = [];
        let rngMin = 0;
        let rngMax = 0;
        for (let i = 0; i < diffDays; i++) {
          const curr = OrgTimestampUtil.add(past, { days: 1 * i });
          const next = OrgTimestampUtil.add(curr, { days: 1 });
          const ts = logData.length > 0 ? logData[0].timestamp : null;
          const datum = { status: null, done: false };

          if (
            ts !== null &&
            OrgTimestampUtil.compare(ts, curr) > 0 &&
            OrgTimestampUtil.compare(ts, next) < 0
          ) {
            logData.shift();
            datum.done = true;

            if (rngMax && rngMax > 0) {
              if (rngMin > 0) {
                datum.status = "b";
              } else {
                if (rngMax === 1) {
                  datum.status = "y";
                } else {
                  datum.status = "g";
                }
              }
            } else if (rngMin > 0) {
              if (rngMin === 1) {
                datum.status = "g";
              } else {
                datum.status = "b";
              }
            } else {
              datum.status = "-";
            }

            rngMin = repMinVal * { d: 1, w: 7 }[repMinU];
            rngMax = repMaxVal ? repMaxVal * { d: 1, w: 7 }[repMaxU] : 0;
            if (rngMax > 0) rngMin--;
          } else if (
            OrgTimestampUtil.compare(OrgTimestampUtil.today(), curr) === 0
          ) {
            if (rngMin > 0) rngMin--;
            if (rngMax && rngMax > 0) rngMax--;
            datum.status = "!";
          } else {
            if (rngMax && rngMax > 0) {
              if (rngMin > 0) {
                datum.status = "b";
                rngMin--;
              } else {
                if (rngMax === 1) {
                  datum.status = "y";
                } else {
                  datum.status = "g";
                }
              }
              rngMax--;
            } else if (rngMin > 0) {
              if (rngMin === 1) {
                datum.status = "y";
              } else {
                datum.status = "b";
              }
              rngMin--;
            } else {
              datum.status = "-";
            }
          }
          ret.push(datum);
        }
        return ret;
      }
      return new Array(diffDays).fill({ status: "e", done: false });
    });

    return trix;
  }
);

export const getCurrentRouteName = navigationState => {
  if (!navigationState) {
    return null;
  }
  const route = navigationState.routes[navigationState.index];
  // dive into nested navigators
  if (route.routes) {
    return getCurrentRouteName(route);
  }
  return route.routeName;
};

export const getAllTags = createSelector(
  [getBuffers],
  buffers =>
    R.uniq(
      Object.values(buffers).reduce(
        (m, v) =>{
          if(v !== null){
          return m.concat(
            Object.values(getFlattenedBufferObj(v.orgTree)).reduce(
              (m2, v2) => m2.concat(v2.tags || []),
              []
            )
          )
          }else{
           return m;
          }},
        []
      )
    )
);

//--------------------------------------------------------------------------------
export const getInboxEntry = createSelector(
  [getBuffers],
  buffers =>
    Object.entries(buffers).filter(([k, b]) => {
      if (k.indexOf("inbox.org") !== -1) return b;
    })[0]
);

export const getInboxBufferID = createSelector(
  [getInboxEntry],
  inboxEntry => {
    return inboxEntry ? inboxEntry[0] : null;
  }
);

export const getInboxBuffer = createSelector(
  [getInboxEntry],
  inboxEntry => {
    return inboxEntry ? inboxEntry[1] : null;
  }
);

//--------------------------------------------------------------------------------

export const getHabitBufferEntry = createSelector(
  [getBuffers],
  buffers =>
    Object.entries(buffers).filter(([k, b]) => {
      if (k.indexOf("habits.org") !== -1) return b;
    })[0]
);

export const getHabitBufferID = createSelector(
  [getHabitBufferEntry],
  habitBufferEntry => {
    return habitBufferEntry ? habitBufferEntry[0] : null;
  }
);

export const getHabitBuffer = createSelector(
  [getHabitBufferEntry],
  habitBufferEntry => {
    return habitBufferEntry ? habitBufferEntry[1] : null;
  }
);
