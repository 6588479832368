import { withStyles } from '@material-ui/core/styles';

import MuiSelect from "@material-ui/core/Select";
import MuiListItemText from "@material-ui/core/ListItemText";
import MuiTableCell from '@material-ui/core/TableCell';

export const Select = withStyles({
  root:{
    paddingTop:0,
    paddingBottom:0,
    paddingRight:18,
    minWidth: 0,
    fontSize: "0.75rem"
  },  
  // select:{
  //   paddingTop:0,
  //   paddingBottom:0
  // }  
})(MuiSelect);

export const ListItemText = withStyles({
  root:{
    marginTop:0,
    marginBottom:0,
  },
  primary:{
    fontSize: "0.75rem"
  }
})(MuiListItemText);

export const TableCell = withStyles({
  root:{
    padding:0,
    fontSize:"0.6rem",
    border: "1px solid rgb(224, 224, 224)"
  },
})(MuiTableCell);
