import React from "react";

import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import OKCancel from "../OKCancel.js";

const NoteDialog = ({ visible, text, onChange, onEdit, onOk, onCancel }) => (
  <Dialog
    /* animationType={"none"} */
    /* transparent={false} */
    open={visible}
    /* onRequestClose={() => { */
    /*   alert("Note Dialog has been closed."); */
    /*   console.log("Note Dialog has been closed."); */
    /* }} */
  >
    <div
      style={{
        display: "flex",
        marginTop: 40,
        flexDirection: "column"
      }}
    >
      <TextField
        style={{ height: "50%", borderColor: "#ccc", borderWidth: 1 }}
        multiline={true}
        value={text || ""}
        onChange={onChange}
      />
      <div style={{ flex: 1 }}>
        <Button onClick={onEdit}>Edit Date</Button>
      </div>
      <OKCancel onOk={onOk} onCancel={onCancel} />
    </div>
  </Dialog>
);

export default NoteDialog;
