import React from "react";
import { connect } from "react-redux";

class SplitPane extends React.Component {
  render() {
    const { viewA, viewB } = this.props;
    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div
          style={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#999"
          }}
        >
          {viewA}
        </div>
        <div
          style={{
            flex: 1,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#333"
          }}
        >
          {viewB}
        </div>
      </div>
    );
  }
}

export default SplitPane;
