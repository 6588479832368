import React, { Component } from "react";
import { connect } from "react-redux";

import * as R from "ramda";

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import { Select } from "./Styled";
import Checkbox from "@material-ui/core/Checkbox";

import { toggleNodeTag, setNodeTags } from "../actions";

import { getNode, getAllTags } from "../selectors";

class OrgTagsEditable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      freeformVisible: false,
      freeformText: null
    };
  }

  setModalVisible(visible) {
    this.setState({ modalVisible: visible });
  }

  setFreeformVisible(visible) {
    this.setState({ freeformVisible: visible });
  }

  render() {
    const {
      bufferID,
      nodeID,
      tags,
      allTags,
      onToggleTag,
      onSetTags
    } = this.props;

    const tagItems =
      tags.length > 0
        ? tags.map((tag, idx) => {
            return (
              <Box key={idx} /* style={appStyles.container} */>
                <Typography
                  variant="body2"
                  style={[
                    /* appStyles.baseText, */
                    {
                      backgroundColor: "#cccccc",
                      fontSize: 10
                    }
                  ]}
                >
                  {tag}
                </Typography>
              </Box>
            );
          })
        : null;

    const tagList = tagItems ? (
      <Box /* style={appStyles.container} */>{tagItems}</Box>
    ) : null;

    // return (
    //   <Box /* style={appStyles.container} */>
    //     {/* <Modal */}
    //     {/*   animationType={"fade"} */}
    //     {/*   transparent={false} */}
    //     {/*   visible={this.state.modalVisible} */}
    //     {/*   onRequestClose={() => { */}
    //     {/*     alert("Modal has been closed."); */}
    //     {/*   }} */}
    //     {/* > */}
    //     <Box
    //     /* style={[ */
    //     /*   /\* appStyles.container, *\/ */
    //     /*   { marginTop: 22, flexDirection: "column" } */
    //     /* ]} */
    //     >
    //       <Box style={{ flex: 4, backgroundColor: "#ddd" }}>
    //         {tags.map((tag, idx) => (
    //           /* <TouchableHighlight */
    //           /*   key={idx} */
    //           /*   onClick={() => { */
    //           /*     onToggleTag(bufferID, nodeID, tag); */
    //           /*   }} */
    //           /* > */
    //           <div>
    //             <Box style={{ flex: 0 }}>
    //               <Typography
    //                 variant="body2"
    //                 style={[
    //                   /* appStyles.baseText, */
    //                   {
    //                     backgroundColor: "#cccccc",
    //                     fontSize: 10,
    //                     padding: 10
    //                   }
    //                 ]}
    //               >
    //                 {tag}
    //               </Typography>
    //             </Box>
    //           </div>
    //           /* </TouchableHighlight> */
    //         ))}
    //       </Box>
    //       {/* <ScrollBox style={{ flex: 8, backgroundColor: "#eee" }}> */}
    //       <div>
    //         {this.state.freeformVisible ? (
    //           <Box>
    //             <TextField
    //               /* style={appStyles.baseText} */
    //               value={this.state.freeformText}
    //               onChangeText={freeformText => this.setState({ freeformText })}
    //             />
    //             <Box style={{ flexDirection: "row" }}>
    //               <Box /* style={appStyles.container} */>
    //                 <Button
    //                   title={"CANCEL"}
    //                   onClick={() => {
    //                     this.setFreeformVisible(!this.state.freeformVisible);
    //                     this.setState({ freeformText: null });
    //                   }}
    //                 />
    //               </Box>
    //               <Box /* style={appStyles.container} */>
    //                 <Button
    //                   title={"OK"}
    //                   onClick={() => {
    //                     this.setFreeformVisible(!this.state.freeformVisible);
    //                     onToggleTag(bufferID, nodeID, this.state.freeformText);
    //                     this.setState({ freeformText: null });
    //                   }}
    //                 />
    //               </Box>
    //             </Box>
    //           </Box>
    //         ) : (
    //           /* <TouchableHighlight */
    //           /*   style={appStyles.container} */
    //           /*   onClick={() => { */
    //           /*     this.setFreeformVisible(!this.state.freeformVisible); */
    //           /*   }} */
    //           /* > */
    //           <div>
    //             <Typography
    //               variant="body2"
    //               /* style={[ */
    //               /*   /\* appStyles.baseText, *\/ */
    //               /*   { */
    //               /*     backgroundColor: "#cccccc", */
    //               /*     fontSize: 10, */
    //               /*     padding: 10 */
    //               /*   } */
    //               /* ]} */
    //             >
    //               add new
    //             </Typography>
    //           </div>
    //           /* </TouchableHighlight> */
    //         )}
    //         {allTags.map((tag, idx) => (
    //           <Box key={idx} style={{ flex: 0 }}>
    //             {/* <TouchableHighlight */}
    //             {/*   onClick={() => { */}
    //             {/*     onToggleTag(bufferID, nodeID, tag); */}
    //             {/*   }} */}
    //             {/* > */}
    //             <div>
    //               <Typography
    //                 variant="body2"
    //                 /* style={[ */
    //                 /*   /\* appStyles.baseText, *\/ */
    //                 /*   { */
    //                 /*     backgroundColor: "#cccccc", */
    //                 /*     fontSize: 10, */
    //                 /*     padding: 10 */
    //                 /*   } */
    //                 /* ]} */
    //               >
    //                 {tag}
    //               </Typography>
    //             </div>
    //             {/* </TouchableHighlight> */}
    //           </Box>
    //         ))}
    //       </div>
    //       {/* </ScrollBox> */}
    //       <Box /* style={appStyles.container} */>
    //         <Button
    //           title={"DONE"}
    //           onClick={() => {
    //             this.setModalVisible(!this.state.modalVisible);
    //           }}
    //         >
    //           {"DONE"}
    //         </Button>
    //       </Box>
    //     </Box>
    //     {/* </Modal> */}
    //     {/* <TouchableHighlight */}
    //     {/*   onClick={() => { */}
    //     {/*     this.setModalVisible(!this.state.modalVisible); */}
    //     {/*   }} */}
    //     {/*   style={appStyles.container} */}
    //     {/* > */}
    //     <div>
    //       <Box /*style={[ appStyles.container,  { flexDirection: "column" }]}*/>
    //         {tagList}
    //       </Box>
    //     </div>
    //     {/* </TouchableHighlight> */}
    //   </Box>
    // );
    const handleChange = e => {
      onSetTags(bufferID, nodeID, e.target.value);
    };

    return (
      <FormControl>
        {/* <InputLabel htmlFor="select-multiple-checkbox">Tag</InputLabel> */}
        <Select
          multiple
          value={tags}
          onChange={handleChange}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={selected => selected.join(", ")}
          /* MenuProps={MenuProps} */
        >
          {allTags.map(name => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={tags.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { bufferID, nodeID } = ownProps;
  const node = getNode(state, ownProps);
  let allTags = getAllTags(state);
  return {
    tags: node.tags || [],
    allTags
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onToggleTag: (bufferID, nodeID, tag) => {
      dispatch(toggleNodeTag(bufferID, nodeID, tag));
    },
    onSetTags: (bufferID, nodeID, tags) => {
      dispatch(setNodeTags(bufferID, nodeID, tags));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgTagsEditable);
