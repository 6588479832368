export default [
  'none',
  'PROJ',
  'TODO',
  'NEXT',
  'STARTED',
  'WAITING',
  'SOMEDAY',
  'DONE',
  'CANCELLED',
  'TODELEGATE',
  'DELEGATED',
  'COMPLETE',
  'LAYOUT',
  'VIEW',
  'FILE',
  'EMAIL'
];
