import React, { Component, useState } from "react";
// import { connect } from "react-redux";

import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import OrgTimestampUtil from "../utilities/OrgTimestampUtil";

class OrgTimestamp extends Component {
  constructor(props) {
    super(props);

    const date = props.timestamp ? new Date() : new Date();
    const parsedRep = props.timestamp
      ? OrgTimestampUtil.parseRep(props.timestamp.repeat)
      : null;
    this.state = {
      date,
      showDatePicker: false,
      showRepIntPicker: false,
      repInt: parsedRep && parsedRep.repInt ? parsedRep.repInt : "--",
      repMinVal: parsedRep && parsedRep.repMinVal ? parsedRep.repMinVal : "-",
      repMinU: parsedRep && parsedRep.repMinU ? parsedRep.repMinU : "-",
      repMaxVal: parsedRep && parsedRep.repMaxVal ? parsedRep.repMaxVal : "-",
      repMaxU: parsedRep && parsedRep.repMaxU ? parsedRep.repMaxU : "-"
    };
  }

  render() {
    const { timestamp } = this.props;
    const { date } = this.state;
    console.log("DATE:", this.state.date);

    const okCancel = (onCancelPress, onOkPress) => {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div // style={appStyles.container}
            style={{ flex: 1 }}
          >
            <Button onClick={onCancelPress}>Cancel</Button>
          </div>
          <div // style={appStyles.container}
            style={{ flex: 1 }}
          >
            <Button onClick={onOkPress}>OK</Button>
          </div>
        </div>
      );
    };

    const repUnitsPicker = selectedVal => (
      <FormControl /* className={classes.formControl} */>
        {/* <InputLabel htmlFor="age-simple">Age</InputLabel> */}
        <Select
          value={this.state[selectedVal]}
          onChange={e => {
            const itemValue = e.target.value;
            const obj = {};
            obj[selectedVal] = itemValue;
            this.setState(obj);
          }}
          inputProps={{
            name: "repUnitsSelect-" + selectedVal,
            id: "rep-units-select-" + selectedVal
          }}
        >
          <MenuItem value={"-"}>-</MenuItem>
          <MenuItem value={"h"}>h</MenuItem>
          <MenuItem value={"d"}>d</MenuItem>
          <MenuItem value={"w"}>w</MenuItem>
          <MenuItem value={"m"}>m</MenuItem>
          <MenuItem value={"y"}>y</MenuItem>
        </Select>
      </FormControl>
    );

    const repValPicker = selectedVal => {
      let vals = [
        <MenuItem key={"-"} value={"-"}>
          -
        </MenuItem>
      ];
      for (let i = 1; i < 10; i++) {
        const val = i + "";
        vals.push(
          <MenuItem key={i} value={val}>
            {val}
          </MenuItem>
        );
      }
      return (
        <FormControl>
          <Select
            value={this.state[selectedVal]}
            onChange={e => {
              const itemValue = e.target.value;
              const obj = {};
              obj[selectedVal] = itemValue;
              this.setState(obj);
            }}
          >
            {vals}
          </Select>
        </FormControl>
      );
    };

    const labelStr = `${this.props.label}:`;
    let timestampStr, repIntStr;
    if (timestamp) {
      timestampStr = `${timestamp.date.yyyy}-${(
        "" + timestamp.date.mm
      ).padStart(2, "0")}-${("" + timestamp.date.dd).padStart(2, "0")} ${
        timestamp.date.dayName
      } ${("" + timestamp.time.hh).padStart(2, "0")}:${(
        "" + timestamp.time.mm
      ).padStart(2, "0")}`;
      repIntStr = timestamp.repeat; //`${timestamp.repInt || '--'}${timestamp.repMin ||'--'}/${timestamp.repMax || '--'}`;
    } else {
      timestampStr = "----------------------";
      repIntStr = "------";
    }

    const handleDateChange = m => {
      this.setState({ date: m.toDate() });
    };

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div>
          {/* <Swipeout */}
          {/*   autoClose={true} */}
          {/*   right={[ */}
          {/*     { */}
          {/*       text: "clear", */}
          {/*       backgroundColor: "#bb3333", */}
          {/*       onClick: () => { */}
          {/*         this.props.onTimestampClear(); */}
          {/*       } */}
          {/*     } */}
          {/*   ]} */}
          {/* > */}
          <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}>
            <div style={{ flex: 4 }}>
              <Typography variant="body2" /* style={appStyles.baseText} */>
                {labelStr}
              </Typography>
            </div>
            {/* <TouchableHighlight */}
            {/*   style={{ flex: 12, display: "flex", flexDirection: "row" }} */}
            {/* > */}
            <div
              style={{ display: "flex", flexDirection: "row" }}
              onClick={() => {
                this.setState({ showDatePicker: !this.state.showDatePicker });
              }}
            >
              <div style={{ flex: 2 }}>
                <Typography variant="body2" /* style={appStyles.baseText} */>
                  {timestampStr}
                </Typography>
              </div>
              <div /* style={appStyles.container} */>
                <Typography variant="body2" /* style={appStyles.baseText} */>
                  {repIntStr}
                </Typography>
              </div>
            </div>
            {/* </TouchableHighlight> */}
          </div>
          {/* </Swipeout> */}
          {/* {showDatePicker} */}

          <Dialog open={this.state.showDatePicker}>
            <DateTimePicker
              variant="static"
              value={date}
              onChange={handleDateChange.bind(this)}
            />
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ flex: 1 }}>
                <FormControl /* className={classes.formControl} */>
                  {/* <InputLabel htmlFor="age-simple">Age</InputLabel> */}
                  <Select
                    value={this.state.repInt}
                    onChange={e => {
                      this.setState({ repInt: e.target.value });
                    }}
                    inputProps={{
                      name: "age",
                      id: "age-simple"
                    }}
                  >
                    <MenuItem value={"--"}>--</MenuItem>
                    <MenuItem value={"+"}>+</MenuItem>
                    <MenuItem value={"++"}>++</MenuItem>
                    <MenuItem value={".+"}>.+</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ flex: 1 }}>{repValPicker("repMinVal")}</div>
              <div style={{ flex: 1 }}>{repUnitsPicker("repMinU")}</div>
              <div style={{ flex: 1 }}>{repValPicker("repMaxVal")}</div>
              <div style={{ flex: 1 }}>{repUnitsPicker("repMaxU")}</div>
            </div>

            {okCancel(
              () => {
                this.setState({ showDatePicker: !this.state.showDatePicker });
              },
              () => {
                this.props.onTimestampUpdate(this.state.date);

                const {
                  repInt,
                  repMaxVal,
                  repMaxU,
                  repMinVal,
                  repMinU
                } = this.state;

                if (repInt !== "--") {
                  if (
                    repMaxVal !== "-" &&
                    repMaxU !== "-" &&
                    repMinVal !== "-" &&
                    repMinU !== "-" &&
                    parseInt(repMinVal) < parseInt(repMaxVal)
                    // you would also want to make sure the repMaxU is >= repMinU
                  ) {
                    // case has min and max repeat rates defined
                    this.props.onTimestampRepIntUpdate(
                      repInt,
                      repMinVal + repMinU,
                      repMaxVal + repMaxU
                    );
                  } else if (repMinVal !== "-" && repMinU !== "-") {
                    // case has only min repeat rate defined
                    this.props.onTimestampRepIntUpdate(
                      repInt,
                      repMinVal + repMinU,
                      null
                    );
                  }
                } else {
                  if (
                    repMaxVal === "-" &&
                    repMaxU === "-" &&
                    repMinVal === "-" &&
                    repMinU === "-"
                  ) {
                    this.props.onTimestampRepIntUpdate(null, null, null);
                  }
                }

                this.setState({ showDatePicker: !this.state.showDatePicker });
              }
            )}
          </Dialog>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default OrgTimestamp;
