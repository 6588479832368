import React, { Component } from "react";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import BookIcon from "@material-ui/icons/Book";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";

class OrgBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      text: undefined,
      isCollapsed: true
    };
  }

  _toggleCollapse() {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  componentWillMount() {
    // console.log('WILL MOUNT');
    // console.log(this.props);
    // console.log(this.state);
    this.setState({ text: this.props.text });
  }

  render() {
    const { onUpdateNodeParagraph, bodyText, section } = this.props;
    const { isCollapsed, isEditing, text } = this.state;

    if (isCollapsed) {
      return (
          <div onClick={this._toggleCollapse.bind(this)}>
          <div
        className="OrgBody"
        style={{
          flexDirection: "row",
          backgroundColor: "#cccccc"
        }}
          >
          <FormatAlignLeftIcon fontSize="small" style={{ marginLeft: 5 }} />
          </div>
          </div>
      );
    } else {
    const showEditor = isEditing ? (
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div /*style={appStyles.container}*/>
            <Button
              onClick={() => {
                this.setState({ isEditing: false });
                this._toggleCollapse();
              }}
              // color="#aa3333"
            >
              cancel
            </Button>
          </div>
          <div /*style={appStyles.container}*/>
            <Button
              onClick={() => {
                onUpdateNodeParagraph(text);
                this.setState({ isEditing: false });
                this._toggleCollapse();
              }}
              // color="#33aa33"
            >
              ok
            </Button>
          </div>
        </div>
        <div style={{ overflowY: "scroll" }}>
          <div /* style={[appStyles.container]} */>
            <TextField
              style={
                /* appStyles.baseText, */
                /* styles.text, */
                { width: "100%" }
                /* appStyles.border */
              }
              value={text === undefined ? bodyText : text}
              multiline={true}
              autoFocus={true}
              onChange={e => {
                this.setState({ text: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
    ) : (
      <Box>
        <Box
          className="OrgBody"
          style={{
            flexDirection: "row",
            backgroundColor: "#cccccc"
          }}
        >
          <Box onClick={this._toggleCollapse.bind(this)}>
            {isCollapsed ? (
                <FormatAlignLeftIcon fontSize="small" style={{ marginLeft: 5 }}/>
            ) : (
                <FormatAlignLeftIcon fontSize="small" style={{ marginLeft: 5 }}/>
            )}
          </Box>
        </Box>
        {isCollapsed ? (
          <Box style={{ overflowX: "scroll" }}>
              <Typography
                variant="body2"
                style={
                  /* appStyles.baseText, */
                  /* styles.text, */
                  { width: "100%",
                    whiteSpace: 'pre-line'}
                  /* appStyles.border */
                }
              >
                {
                  // bodyText
                  text
                }
              </Typography>
          </Box>
        ) : (
          <Box
            onClick={
              isCollapsed ? null : () => this.setState({ isEditing: true })
            }
          >
            <Box style={{ overflowX: "scroll" }}>
                <Typography
                  variant="body2" style={{whiteSpace: 'pre-line'}}/* style={[styles.container, styles.text]} */
                >
                  {
                    //bodyText
                    text
                  }
                </Typography>
              </Box>
          </Box>
        )}
      </Box>
    );
    return <Box style={{ flex: 16 }}>{showEditor}</Box>;
}
  }
}

export default OrgBody;
