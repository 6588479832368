import React, { Component } from "react";
// import { TouchableHighlight, ScrollView, Text, View } from "react-native";
// import Ionicons from "react-native-vector-icons/Ionicons";
// import Swipeout from "react-native-swipeout";
import SwipeableViews from "react-swipeable-views";

import OrgLogbookItem from "./OrgLogbookItem";
import BookIcon from "@material-ui/icons/Book";

export default class OrgLogbook extends Component {
  constructor(props) {
    super(props);
    this.state = { isCollapsed: this.props.isCollapsed };
  }
  _toggleCollapse() {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }
  render() {
    const { log, onRemoveLogNote, onUpdateLogNote, onAddLogNote } = this.props;
    if (this.state.isCollapsed) {
      return (
        <div
          className="OrgLogbook"
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#cccccc"
          }}
          onClick={this._toggleCollapse.bind(this)}
        >
          <BookIcon fontSize="small" />
        </div>
      );
    } else {
      const listItems =
        log &&
        log.items &&
        log.items.map((le, idx) => {
          return (
            <OrgLogbookItem
              key={idx}
              idx={idx}
              type={le.type}
              logItem={le}
              onRemoveLogNote={onRemoveLogNote}
              onUpdateLogNote={onUpdateLogNote}
            />
          );
        });
      return (
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          {/* <Swipeout */}
          {/*   autoClose={true} */}
          {/*   left={[ */}
          {/*     { */}
          {/*       text: "addOne", */}
          {/*       backgroundColor: "#33bb33", */}
          {/*       onClick: () => { */}
          {/*         onAddLogNote(); */}
          {/*       } */}
          {/*     } */}
          {/*   ]} */}
          {/* > */}
          {/* <TouchableHighlight onClick={this._toggleCollapse.bind(this)}> */}
          <div
            className="OrgLogbook"
            style={{
              display: "flex",
              flexDirection: "row",
              backgroundColor: "#cccccc"
            }}
          >
            <div onClick={this._toggleCollapse.bind(this)}>
              <BookIcon fontSize="small" />
            </div>
            <SwipeableViews enableMouseEvents={true}>
              <div style={{ width: 50 }}>...</div>
              <div
                style={{ width: 50, backgroundColor: "#33bb33" }}
                onClick={() => {
                  onAddLogNote();
                }}
              >
                addOne
              </div>
            </SwipeableViews>
          </div>
          {/*   </TouchableHighlight> */}
          {/* </Swipeout> */}
          <div className="OrgDrawerProperties">{listItems}</div>
        </div>
      );
    }
  }
}
