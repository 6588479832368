import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { DateTimePicker } from "@material-ui/pickers";
import OKCancel from "../OKCancel.js";

const DateDialog = ({ visible, date, onChange, onOk, onCancel }) => (
  <Dialog
    /* animationType={"fade"} */
    /* transparent={false} */
    open={visible}
    /* onRequestClose={() => { */
    /*   alert("Date Dialog has been closed."); */
    /* }} */
  >
    <div style={{ flex: 1, marginTop: 22 }}>
      <DateTimePicker
        label="DateTimePicker"
        inputVariant="outlined"
        value={date}
        onChange={onChange}
      />
      <OKCancel onOk={onOk} onCancel={onCancel} />
    </div>
  </Dialog>
);

export default DateDialog;
