import React, { Component } from "react";
import List from "./List";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableCell } from './Styled';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class OrgTable extends Component {
  render(){
    const {rows} = this.props;

    const rowComps = rows.map((r, idx)=>{
        const cellComps = r.cells.map((c)=>{
            const {contents} = c;
            return <TableCell>{ contents }</TableCell>
        })
        return <TableRow key={idx}>{cellComps}</TableRow>
    })

      return (<Table size="small">
          <TableBody>
              {rowComps}
          </TableBody>
      </Table>)
  }  
}

export default OrgTable;
