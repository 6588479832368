import React from "react";
import { connect } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import OKCancel from "../OKCancel.js";

import OKEditCancelDialog from "./OKEditCancelDialog";
import DateDialog from "./DateDialog";
import NoteDialog from "./NoteDialog";
import HabitList from "./HabitList";
import HabitGrid from "./HabitGrid";

import OrgNodeUtil from "../../utilities/OrgNodeUtil";
import OrgTimestampUtil from "../../utilities/OrgTimestampUtil";
//const OrgPlanningUtil = require('../utilities/OrgPlanningUtil');
/* const OrgDrawerUtil = require('../utilities/OrgDrawerUtil');*/

import {
  momentFromTS,
  momentToTS,
  parseDate,
  serializeTS
} from "../../utilities/utils";

import { completeHabit, resetHabit } from "../../actions";

import {
  getAllNodes,
  getFlattenedBufferObj,
  getAllHabits,
  getHabitMatrix
} from "../../selectors";

class OrgHabits extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currX: 0,
      prevDX: 0,

      dateDialogVisible: false,
      dateDialogDate: new Date(),
      dateDialogNodeID: null,
      noteDialogVisible: false,
      noteDialogText: null,
      noteDialogNodeID: null,
      OKEditCancelDialogNodeID: null,
      OKEditCancelDialogVisible: false
    };
  }

  setDateDialogVisible(visible) {
    this.setState({ dateDialogVisible: visible });
  }

  showDateDialog(nodeID, date) {
    this.setState({
      dateDialogNodeID: nodeID,
      dateDialogDate: new Date(
        date.date.yyyy,
        date.date.mm - 1,
        date.date.dd,
        new Date().getHours(),
        new Date().getMinutes()
      )
    });
    this.setDateDialogVisible(true);
  }

  setNoteDialogVisible(visible) {
    this.setState({ noteDialogVisible: visible });
  }

  setOKEditCancelDialogVisible(visible) {
    this.setState({ OKEditCancelDialogVisible: visible });
  }

  render() {
    const {
      date,
      habits,
      // habitData,
      onHabitPress,
      incrementDate,
      decrementDate
    } = this.props;
    const {
      dateDialogVisible,
      dateDialogNodeID,
      dateDialogDate,
      noteDialogVisible,
      noteDialogNodeID,
      noteDialogText,
      OKEditCancelDialogNodeID,
      OKEditCancelDialogVisible
    } = this.state;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div
          className={"unselectable"}
          style={{ flex: 1, overflowY: "scroll" }}
        >
          <OKEditCancelDialog
            visible={OKEditCancelDialogVisible}
            onEditDateClick={() => {
              this.setOKEditCancelDialogVisible(!OKEditCancelDialogVisible);
              this.showDateDialog(OKEditCancelDialogNodeID, date);
            }}
            onOk={() => {
              const tdate = OrgTimestampUtil.clone(date);
              console.log(OKEditCancelDialogNodeID, tdate);
              onHabitPress(OKEditCancelDialogNodeID, tdate);
              this.setOKEditCancelDialogVisible(!OKEditCancelDialogVisible);
            }}
            onCancel={() => {
              this.setOKEditCancelDialogVisible(!OKEditCancelDialogVisible);
            }}
          />

          <DateDialog
            visible={dateDialogVisible}
            date={dateDialogDate}
            onChange={m => this.setState({ dateDialogDate: m.toDate() })}
            onOk={() => {
              this.setDateDialogVisible(!dateDialogVisible);
              const timestamp = OrgTimestampUtil.parseDate(dateDialogDate);
              onHabitPress(dateDialogNodeID, timestamp, noteDialogText);
            }}
            onCancel={() => {
              this.setDateDialogVisible(!dateDialogVisible);
            }}
          />

          <NoteDialog
            visible={noteDialogVisible}
            text={noteDialogText}
            onChange={e => {
              this.setState({ noteDialogText: e.target.value });
            }}
            onEdit={() => {
              this.setNoteDialogVisible(!noteDialogVisible);
              this.showDateDialog(noteDialogNodeID, date);
            }}
            onOk={() => {
              this.setNoteDialogVisible(!noteDialogVisible);
              const tdate = OrgTimestampUtil.clone(date);
              tdate.time.hh += OrgTimestampUtil.now().time.hh;
              tdate.time.mm += OrgTimestampUtil.now().time.mm;
              onHabitPress(noteDialogNodeID, tdate, noteDialogText);
            }}
            onCancel={() => {
              this.setNoteDialogVisible(!noteDialogVisible);
            }}
          />

          <div style={{ display: "flex", flexDirection: "row" }}>
            <HabitList
              habits={habits}
              onItemClick={h => {
                const propDrawer = OrgNodeUtil.getPropDrawer(h);
                const { LOGGING } = propDrawer.props;
                this.setState({ noteDialogText: null });
                switch (LOGGING) {
                  case "DONE(@)":
                    this.setState({ noteDialogNodeID: h.id });
                    this.setNoteDialogVisible(!noteDialogVisible);
                    break;
                  default:
                    if (LOGGING) {
                      console.log(
                        "UNHANDLED LOGGING TYPE IN ORGHABITS!! -- ",
                        propDrawer.props.LOGGING
                      );
                    } else {
                      this.setState({ OKEditCancelDialogNodeID: h.id });
                      this.setOKEditCancelDialogVisible(
                        !OKEditCancelDialogVisible
                      );
                      /* showOKEditCancelSheet(h.id); */
                    }
                    break;
                }
              }}
            />
            <HabitGrid
              date={date}
              onIncr={incrementDate}
              onDecr={decrementDate}
            />
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

////////////////////////////////////////////////////////////////////////////////

const mapStateToProps = (state, ownProps) => {
  const date = ownProps.date;
  const nodes = getAllNodes(state);
  const habits = getAllHabits(state);

  return {
    habits,
    date
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onHabitPress: (nodeID, date, noteText = null) =>
      dispatch(someAction(nodeID, date, noteText))
  };
};

function someAction(nodeID, date, noteText) {
  return (dispatch, getState) => {
    const state = getState();
    const nowStr = date.value; //;OrgTimestampUtil.now());
    // super inefficient way of finding bufferID from nodeID !!!!
    let bufferID = Object.entries(state.orgBuffers).reduce((M, V) => {
      if (M === undefined) {
        const hasId = Object.keys(getFlattenedBufferObj(V[1].orgTree)).reduce(
          (m, v) => {
            return m || v === nodeID;
          },
          false
        );
        if (hasId) {
          return V[0];
        } else {
          return undefined;
        }
      }
      return M;
    }, undefined);

    dispatch(completeHabit(bufferID, nodeID, nowStr, noteText));
    dispatch(resetHabit(bufferID, nodeID, nowStr));
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgHabits);
