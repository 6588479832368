import React, { Component } from "react";
// import {
//   Text,
//   TextField,
//   div,
//   Button,
//   TouchableHighlight
// } from 'react-native';
// import Swipeout from 'react-native-swipeout';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class OrgDrawerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      oldKey: undefined,
      propKey: undefined,
      propVal: undefined
    };
  }

  componentWillMount(foo, bar, baz) {
    if (this.props.propKey === "" && this.props.propVal === "") {
      this.onEditBegin();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      propKey: nextProps.propKey,
      propVal: nextProps.propVal
    });
    if (
      nextProps.disabled &&
      nextProps.disabled !== this.props.disabled &&
      this.state.isEditing
    ) {
      this.setState({ isEditing: false });
      this.removeIfEmpty();
    }
  }

  removeIfEmpty() {
    const { onRemoveProp } = this.props;
    const key = this.determineKey();
    const val = this.determineVal();
    if (key === "" && val === "") onRemoveProp("");
  }

  onEditBegin() {
    const { idx, onItemEditBegin } = this.props;
    const key = this.determineKey();

    onItemEditBegin(idx);

    this.setState({
      isEditing: true,
      oldKey: key
    });
  }

  onEditEnd() {
    const { onItemEditEnd } = this.props;

    onItemEditEnd();
    this.setState({
      isEditing: false
    });

    this.removeIfEmpty();
  }

  determineKey() {
    return this.state.propKey === undefined
      ? this.props.propKey
      : this.state.propKey;
  }

  determineVal() {
    return this.state.propVal === undefined
      ? this.props.propVal
      : this.state.propVal;
  }

  render() {
    const {
      idx,
      onItemEditBegin,
      onUpdateProp,
      onRemoveProp,
      disabled
    } = this.props;

    const { oldKey, isEditing } = this.state;
    const key = this.determineKey();
    const val = this.determineVal();

    const showKeyEditor = this.props.propKey ? (
      <Typography
        variant="body2"
        style={{
          flex: 1,
          borderColor: "gray",
          backgroundColor: "#dedede",
          borderWidth: 1
        }}
      >
        {key}
      </Typography>
    ) : (
      <TextField
        style={{ flex: 1, borderColor: "gray", borderWidth: 1 }}
        value={key}
        onChange={e => this.setState({ propKey: e.target.value })}
      />
    );

    const base = (
      <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}>
        <div style={{ flex: 4 }}>
          <Typography
            variant="body2"
            style={{
            //   fontFamily: "space-mono",
              fontSize: 10
            }}
          >
            {key}
          </Typography>
        </div>
        <div style={{ flex: 12 }}>
          <Typography
            variant="body2"
            style={{
            //   fontFamily: "space-mono",
              fontSize: 10
            }}
          >
            :{val}
          </Typography>
        </div>
      </div>
    );

    const showEditor =
      !disabled && isEditing ? (
        <div style={{ flex: 1, flexDirection: "row" }}>
          <Button
            onClick={() => {
              onUpdateProp(idx, oldKey, key, val);
              this.onEditEnd();
            }}
            /* color="#33aa33" */
          >
            ok
          </Button>
          <Button onClick={this.onEditEnd.bind(this)} /* color="#aa3333" */>
            cancel
          </Button>
          {showKeyEditor}
          <TextField
            style={{ flex: 1, borderColor: "gray", borderWidth: 1 }}
            value={val}
            onChange={e => this.setState({ propVal: e.target.value })}
          />
        </div>
      ) : (
        // <Swipeout
        //   autoClose={true}
        //   right={[
        //     {
        //       backgroundColor: "#bb3333",
        //       text: "delete",
        //       onClick: () => {
        //         onRemoveProp(key);
        //       }
        //     }
        //   ]}
        //   style={{ flex: 16 }}
        // >
        <div style={{ flex: 16 }} onClick={this.onEditBegin.bind(this)}>
          {base}
        </div>
        // </Swipeout>
      );

    return (
      <div style={{ display: "flex", flexDirection: "row" }}>{showEditor}</div>
    );
  }
}

export default OrgDrawerItem;
