import React, { Component } from "react";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";

// import { StyleSheet, Text } from 'react-native';

// import ModalDropdown from 'react-native-modal-dropdown';

import {
  updateNodeTimestamp,
  updateNodeTodoKeyword,
  completeTodo
} from "../actions";

import { getNode } from "../selectors";

// import appStyles from "../styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { Select, ListItemText } from "./Styled";

import keywords from "../constants/TodoKeyword"; // ?;

import OrgTimestampUtil from "../utilities/OrgTimestampUtil";

class OrgTodoKeywordEditable extends Component {
  constructor(props) {
    super(props);
    // let keywords = OrgHeadlineUtil.keywords().slice(0);
    // keywords.shift('none');
    this.state = {
      keywords
    };
  }

  render() {
    const {
      keyword,
      onNodeTodoKeywordUpdate,
      onSelectDone,
      bufferID,
      nodeID
    } = this.props;
    const { keywords } = this.state;
    const dispKeyword = keyword ? keyword : "none";
    const handleChange = e => {
      const targKeyword = e.target.value;
      // TODO: move this logic to recucers or out of this ccomponent somewhere
      if (keyword === "TODO" && targKeyword === "DONE")
        onSelectDone(bufferID, nodeID);
      onNodeTodoKeywordUpdate(bufferID, nodeID, targKeyword);
    };
    return (
      <FormControl /* className={classes.formControl} */>
        {/* <InputLabel htmlFor="todo-keyword-select">{dispKeyword}</InputLabel> */}
        <Select
          value={dispKeyword}
          onChange={handleChange}
          input={<Input id="todo-keyword-select" />}
        >
          {keywords.map(name => (
            <MenuItem key={name} value={name}>
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );

    // const todoKeyword = (
    //   // <ModalDropdown
    //   //   options={this.state.keywords}
    //   //   onSelect={idx => {
    //   //     const targKeyword = keywords[idx];
    //   //     if (keyword === "TODO" && targKeyword === "DONE")
    //   //       onSelectDone(bufferID, nodeID);
    //   //     onNodeTodoKeywordUpdate(bufferID, nodeID, targKeyword);
    //   //   }}
    //   // >
    //   <Typography
    //     variant="body2"
    //     /* style={[ */
    //     /*   /\* appStyles.baseText, *\/ */
    //     /*   { */
    //     /*     backgroundColor: keyword */
    //     /*       ? "#f00" //OrgHeadlineUtil.colorForKeyword(keyword) */
    //     /*       : "#fff" */
    //     /*   } */
    //     /* ]} */
    //   >
    //     {dispKeyword}
    //   </Typography>
    //   // </ModalDropdown>
    // );

    // return todoKeyword;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { bufferID, nodeID } = ownProps;

  return {
    keyword: getNode(state, ownProps).keyword
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onNodeTodoKeywordUpdate: (bufferID, nodeID, keyword) => {
      dispatch(updateNodeTodoKeyword(bufferID, nodeID, keyword));
    },
    onSelectDone: (bufferID, nodeID, noteText = "") => {
      dispatch(
        completeTodo(
          bufferID,
          nodeID,
          OrgTimestampUtil.serialize(OrgTimestampUtil.now()),
          noteText
        )
      );
      dispatch(
        updateNodeTimestamp(bufferID, nodeID, "CLOSED", OrgTimestampUtil.now())
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgTodoKeywordEditable);
