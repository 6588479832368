import React from "react";
import { connect } from "react-redux";
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

import OrgList from "../components/OrgList";
import OrgSection from "../components/OrgSection";

const ExpansionPanel = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelDetails = withStyles({
    root: {
        padding: '0px 24px 24px'
    }
})(MuiExpansionPanelDetails);

export class OrgBuffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isCollapsed: true };
  }
  render() {
    const { bufferID, tree, selectedNodeID, onNodeSelect } = this.props;
    const { isCollapsed } = this.state;
    let childList = null;

    if (!isCollapsed) {
      childList = (
        <OrgList
          data={tree.headlines}
          bufferID={bufferID}
          isLocked={this.props.isLocked}
          selectedNodeID={selectedNodeID}
          onNodeSelect={onNodeSelect}
        />
      );
    } else {
      childList = [];
    }

    return (
      <ExpansionPanel expanded={!isCollapsed}>
        <ExpansionPanelSummary>
          <div
            onClick={() => {
              this.setState({ isCollapsed: !isCollapsed });
            }}
          >
            <Typography
              variant="body2"
              style={{ backgroundColor: "#000", color: "#fff" }}
            >
              {bufferID}
            </Typography>
          </div>

          {!this.props.isLocked && (
            <Button
              size="small"
              style={{height: 17.1667}}
              onClick={() => {
                this.props.onAddOne(bufferID);
              }}
              /* color="#841584" */
            >
              Add Child
            </Button>
          )}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
            <div style={{width:"100%",
                         display:"flex",
                         flexDirection: "column"}}>
                <div style={{flex:1}}>
                    <OrgSection bufferID={bufferID} nodeID={null}/>
                </div>
                <div style={{flex:1}}>
                    {childList}
                </div>
            </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.bufferID) {
    const bufferID = ownProps.bufferID;
    console.log(bufferID);
    console.log(state.orgBuffers)
    return {
      bufferID: bufferID,
      tree: state.orgBuffers[bufferID].orgTree
    };
  } else {
    return {
      tree: {}
    };
  }
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgBuffer);
