import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { registerDbxAccessToken, addNewNode } from "../../actions";

import OrgBuffer from "../../components/OrgBuffer";
import OrgHeadlineToo from "../../components/OrgHeadlineToo";
import DropboxDataSource from "../../utilities/DropboxDataSource";

import SearchFieldUI from "./SearchFieldUI";
import KeywordFilterUI from "./KeywordFilterUI.js";
import TagFilterUI from "./TagFilterUI.js";

import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";

import { getAllTags, getFlattenedBufferObj } from "../../selectors";

import keywords from "../../constants/TodoKeyword";

class HomeScreen extends React.Component {
  state = {
    buffersLoaded: false,
    inboxFileIsOk: false,
    searchStr: "",
    tagFilters: [],
    tagFilterType: "AND",
    isLocked: true,
    keywords,
    keywordFilter: "",

    selectedNodeID: null  
  };

  // componentWillMount() {
  //   this.setState({
  //     keywords,
  //   });
  //   // this.props.loadOrgFiles();
  // }

  // toggleTagFilter(tag) {
  //   let tags = this.state.tagFilters || [];
  //   if (R.contains(tag, tags)) {
  //     tags = R.without(tag, tags);
  //   } else {
  //     tags = R.insert(tags.length, tag, tags);
  //   }
  //   this.setState({ tagFilters: tags });
  // }

  render() {
    const { buffers, onAddOne, allTags } = this.props;
    const { tagFilters, tagFilterType, keywordFilter, selectedNodeID } = this.state;

    const filterTags = pool => {
      const results = R.filter(n => {
        const i = R.intersection(tagFilters, n.tags);
        if (tagFilterType === "AND") {
          return i.length === tagFilters.length;
        } else if (tagFilterType === "OR") {
          return i.length > 0;
        }
      }, pool);
      return results;
    };

    const filterKeywords = pool => {
      const results = R.filter(
        n => n.keyword && n.keyword.search(keywordFilter) > -1,
        pool
      );
      return results;
    };

    const search = pool => {
      const results = R.filter(
        n =>
          n.content.toLowerCase().search(this.state.searchStr.toLowerCase()) >
          -1,
        pool
      );
      return results;
    };

    if (Object.entries(buffers).length > 0) {
      const doKeywordFilter = keywordFilter !== "" && keywordFilter !== "none"; //this.state.keywords.length - 1;
      const doTagFilter = this.state.tagFilters.length > 0;
      const doSearch = this.state.searchStr && this.state.searchStr !== "";

      let display;
      if (doKeywordFilter || doTagFilter || doSearch) {
        let pool = R.reduce(
          (m, b) =>
            R.concat(
              R.reduce(
                (m2, n) =>
                  R.insert(
                    m2.length,
                    {
                      bufferID: b[0],
                      nodeID: n[1].id,
                      content: n[1].title,
                      level: n[1].stars,
                      keyword: n[1].keyword,
                      tags: n[1].tags || []
                    },
                    m2
                  ),
                [],
                Object.entries(getFlattenedBufferObj(b[1].orgTree))
              ),
              m
            ),
          [],
          Object.entries(buffers)
        );

        if (doSearch) {
          pool = search(pool);
        }

        if (doTagFilter) {
          pool = filterTags(pool);
        }

        if (doKeywordFilter) {
          pool = filterKeywords(pool);
        }

        display = R.map(n => {
          return (
            <OrgHeadlineToo
              key={n.nodeID}
              bufferID={n.bufferID}
              nodeID={n.nodeID}
              isLocked={true}
            />
          );
        }, pool);
      } else {
        display = Object.entries(buffers).map(e => {
          return (
            <OrgBuffer
              key={e[0]}
              bufferID={e[0]}
              isLocked={this.state.isLocked}
              onAddOne={onAddOne}
              selectedNodeID={selectedNodeID}
              onNodeSelect={(nodeID)=>{
               this.setState({selectedNodeID: nodeID})}}
            />
          );
        });
      }

      return (
        <div
          style={{
            overflowY: "hidden",
            height: "100%",
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            <SearchFieldUI value={this.state.searchStr} onChange={e => this.setState({ searchStr: e.target.value })}/>
            <div
              style={{
                display: "flex",
                flexDirection: "row"
              }}
            >
              <div
                style={{
                  flex: 2
                }}
              >
                <KeywordFilterUI
                    keywords={keywords}
                    keywordFilter={keywordFilter}
                    onChange={e => {
                        this.setState({ keywordFilter: e.target.value });
                    }}
                    renderValue={selected => selected}/>
              </div>
              <div
                style={{
                  flex: 3
                }}
              >
             <TagFilterUI
                 allTags={allTags}
                 tagFilters={tagFilters}
                 onChange={e => {
                     console.log(e.target.value);
                     this.setState({ tagFilters: e.target.value });
                 }}
                 renderValue={selected => selected.join(", ")}
                 toggleAndOr={() => {
                     if (this.state.tagFilterType === "AND") {
                         this.setState({ tagFilterType: "OR" });
                     } else {
                         this.setState({ tagFilterType: "AND" });
                     }
                 }}
                 andOrVal={this.state.tagFilterType}/>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              this.setState({ isLocked: !this.state.isLocked });
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "#cccccc"
              }}
            >
              {this.state.isLocked ? (
                <LockIcon fontSize="small" style={{ marginLeft: 5 }} />
              ) : (
                <LockOpenIcon fontSize="small" style={{ marginLeft: 5 }} />
              )}
            </div>
          </div>
          <div style={{ overflowY: "scroll", flex: 1 }}>{display}</div>
        </div>
      );
    }

    return <div>{JSON.stringify(buffers)}</div>;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    buffers: state.orgBuffers,
    allTags: getAllTags(state),
    settings: state.settings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAddOne: bufferID => {
      dispatch(addNewNode(bufferID));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeScreen);
