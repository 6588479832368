import React, { Component } from "react";

import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import BookIcon from "@material-ui/icons/Book";
import {colorForKeyword} from '../utilities/utils';
//import { makeStyles } from '@material-ui/core/styles';// import {
//   Typography,
//   TypographyInput,
//   View,
//   ScrollView,
//   StyleSheet,
//   Button,
//   TouchableHighlight
// } from 'react-native';
// import Swipeout from 'react-native-swipeout';

const styles = {
//   container: {
//     flex: 1
//   },
  text: {
    // fontFamily: 'Menlo',
    fontSize: 10
  },
//   textInput: {
//     fontFamily: 'Menlo',
//     fontSize: 10,
//     padding: 5
//   },
//   border: {
//     borderWidth: 1,
//     borderStyle: 'solid',
//     margin: 5
//   },
//   dropdown: {
//     position: 'absolute',
//     height: (33 + StyleSheet.hairlineWidth) * 5,
//     borderWidth: StyleSheet.hairlineWidth,
//     borderColor: 'lightgray',
//     borderRadius: 2,
//     backgroundColor: 'white'
//     //justifyContent: 'center'
//   },
//   modal: {
//     flexGrow: 1
//   }
};

class OrgDrawerItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      text: undefined
    };
  }

  render() {
    let showEditor = (
      <div>
        <Typography style={styles.text}>{"editor"}</Typography>
      </div>
    );
    let ret;
    let le = this.props.logItem;
    let text = le.text ? (
      <Typography
        style={{
          flex: 1,
          fontSize: 10
        }}
      >
        {le.text}
      </Typography>
    ) : null;
    switch (le.type) {
      case "state":
        let strip = (str)=>(str.substr(1,str.length-2));
        let st = le.state;
        let frm = le.from;
      let stColor = colorForKeyword(strip(st));
      let frmColor = colorForKeyword(strip(frm));
      let stTxt = `state ${st}`;
      let frmTxt = `from ${frm}`;
      let tsTxt = `${le.timestamp.value}`;
        ret = (
          <div style={{ flex: 16 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ /*flex: 4*/ backgroundColor: stColor }}>
                <Typography style={styles.text}>{stTxt}</Typography>
              </div>
            <div style={{ /*flex: 4*/ backgroundColor: frmColor}}>
                <Typography style={styles.text}>{frmTxt}</Typography>
              </div>
              <div style={{ flex: 1 , textAlign:'right'}}>
                <Typography style={styles.text}>{tsTxt}</Typography>
              </div>
            </div>
            {/* <ScrollView horizontal={true}>{text}</ScrollView> */}
            <div>{text}</div>
          </div>
        );
        break;
      case "clock":
        ret = (
          <div style={{ flex: 16 }}>
            {le.end !== undefined ? (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <Typography style={styles.text}>{`CLOCK: ${
                    le.start.value
                  }--${le.end.value}`}</Typography>
                </div>
                <div style={{}}>
                  <Typography style={styles.text}>{`=>  ${
                    le.duration
                  }`}</Typography>
                </div>
              </div>
            ) : (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 4 }}>
                  <Typography style={styles.text}>{`CLOCK: ${
                    le.start.value
                  }`}</Typography>
                </div>
              </div>
            )}
          </div>
        );
        break;
      case "note":
        const showEditor = this.state.isEditing ? (
          <div style={{ flex: 16 }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ /*flex: 8*/ }}>
                <Typography style={styles.text}
                >{`Note taken on`}</Typography>
              </div>
            <div style={{ flex: 1, textAlign: 'right' }}>
                <Typography style={styles.text}>{`${
                  le.timestamp.value
                }`}</Typography>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
                <div style={{ flex: 1 }}>
                  <Button
                    style={{ flex: 1 }}
                    onClick={() => {
                      this.setState({ isEditing: false, text: le.text });
                    }}
                    /* color="#aa3333" */
                  >
                    "cancel"
                  </Button>
                </div>
                <div style={{ flex: 1 }}>
                  <Button
                    style={{ flex: 1 }}
                    onClick={() => {
                      this.props.onUpdateLogNote(
                        this.props.idx,
                        this.state.text
                      );
                      this.setState({ isEditing: false });
                    }}
                    /* color="#33aa33" */
                  >
                    "ok"
                  </Button>
                </div>
              </div>
              <TextField
                style={{
                  flex: 1,
                  borderColor: "gray",
                  borderWidth: 1,
                  height: 80,
                  fontSize: 10
                }}
                multiline={true}
                autoFocus={true}
                value={
                  this.state.text === undefined
                    ? le.text
                      ? le.text
                      : ""
                    : this.state.text
                }
                onChange={e => this.setState({ text: e.target.value })}
              />
            </div>
          </div>
        ) : (
          // <TouchableHighlight
          //   style={{ flex: 16 }}
          //
          // >
          <div
            style={{ flex: 16 }}
            onClick={() => this.setState({ isEditing: true })}
          >
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ /*flex: 8*/ }}>
                <Typography style={styles.text}
                >{`Note taken on`}</Typography>
              </div>
            <div style={{ flex: 1, textAlign:"right" }}>
                <Typography style={styles.text}>{`${
                  le.timestamp.value
                }`}</Typography>
              </div>
            </div>
            {text}
          </div>
          // </TouchableHighlight>
        );
        ret = showEditor;
        break;
      default:
        ret = (
          <div style={{ flex: 16 }}>
            <Typography
            style={styles.text}
            >{`[unhandled log entry format]`}</Typography>
            {text}
          </div>
        );
        break;
    }

    return (
      // <Swipeout
      //   autoClose={true}
      //   right={[
      //     {
      //       text: "delete",
      //       backgroundColor: "#bb3333",
      //       onClick: () => {
      //         this.props.onRemoveLogNote(this.props.idx);
      //       }
      //     }
      //   ]}
      // >
      <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}>
        {ret}
      </div>
      // </Swipeout>
    );
  }
}

export default OrgDrawerItem;
