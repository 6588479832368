import React from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default class OrgBlock extends React.Component {
  render(){
    const {name, data, contents} = this.props;
      return (<Box style={{fontSize:".65rem"}}>
          <Typography variant="body2">begin_{name} {data}</Typography>
          <Typography variant="body2">{contents}</Typography>
          <Typography variant="body2">end_{name}</Typography>
      </Box>)
  }
}
