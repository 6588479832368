import localforage from "localforage";
import { DBX_ACESS_TOKEN_KEY, BASE_URI } from "../constants";

const Dropbox = require("dropbox").Dropbox;
const CLIENT_ID = "0p1eaon810wylo9"; //"yf56u5iln5btig2";
const dbx = new Dropbox({ clientId: CLIENT_ID });

const hostname = window.location.hostname;
const redirectUri = `${BASE_URI}/auth/dropbox`;
const authUrl = dbx.getAuthenticationUrl(redirectUri);

export const accessTokenIsValid = async accessToken => {
  console.log("check access token still valid???", accessToken);
  // {
  //   url: 'https://api.dropboxapi.com/2/users/get_current_account',

  const foo = await fetch(
    "https://api.dropboxapi.com/2/users/get_current_account",
    {
      method: "POST",
      headers: {
        Authorization: "Bearer " + accessToken
      }
    }
  );

  return foo.status === 200;
};

export const dbxAuthorize = () => {
  const url = "https://www.dropbox.com/oauth2/authorize";
  const params = {
    client_id: CLIENT_ID,
    redirect_uri: redirectUri,
    response_type: "token"
  };
  const queryParams = Object.keys(params)
    .reduce((m, p) => m + "&" + escape(p) + "=" + escape(params[p]), "")
    .slice(1);
  window.location = `${url}?${queryParams}`;
};

export const dbxDeauthorize = async () => {
  const success = await localforage.removeItem(DBX_ACESS_TOKEN_KEY);
  window.location = BASE_URI;
};
