import React, { Component } from "react";
import { connect } from "react-redux";
// import { StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import List from "./List";
// import ListItem from "@material-ui/core/ListItem";
// import L from "@material-ui/core/List";
// import Collapse from "@material-ui/core/Collapse";

class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasKids: false,
      itemData: [],
      isCollapsed: true
    };
  }

  componentDidMount() {
    const { type, getHasKids, path } = this.props;
    if (type === "branch") {
      getHasKids(path, hasKids => this.setState({ hasKids }));
    } else {
      this.setState({ hasKids: false });
    }
  }

  componentWillReceiveProps() {
    const { getItems, path, getHasKids } = this.props;
    if (!this.state.isCollapsed) {
      getItems(path, itemData => {
        this.setState({ itemData });
      });
    }
    getHasKids(path, hasKids => this.setState({ hasKids }));
  }

  toggleCollapse() {
    const { getItems, path } = this.props;
    const { isCollapsed } = this.state;
    if (isCollapsed) {
      getItems(path, itemData => {
        this.setState({ itemData });
      });
    }
    this.setState({ isCollapsed: !isCollapsed });
  }

  render() {
    const {
      title,
      path,
      type,
      getItems,
      getHasKids,
      renderLeafItem,
      renderBranchItem
    } = this.props;
    const { hasKids, itemData, isCollapsed } = this.state;

    let items = null;
    if (!isCollapsed) {
      items = (
        <List
          data={itemData}
          renderItem={(d, idx) => {
            return (
              // <div key={idx}>
              <Tree
                key={idx}
                title={d.title}
                path={d.path}
                getItems={getItems}
                type={d.type}
                getHasKids={getHasKids}
                renderLeafItem={renderLeafItem}
                renderBranchItem={renderBranchItem}
              />
              // </div>
            );
          }}
        />
      );
      items = <div>{items}</div>;
    }

    let touchableMaybe;
    if (type === "branch") {
      const branchItem = renderBranchItem(
        title,
        path,
        type,
        hasKids,
        isCollapsed,
        this.toggleCollapse.bind(this)
      );
      // if (hasKids) {
      touchableMaybe = branchItem;
      // } else {
      //   touchableMaybe = branchItem;
      // }
    } else if (type === "leaf") {
      touchableMaybe = renderLeafItem(title, path, type, hasKids);
    }

    return (
      <div>
        {touchableMaybe}
        {items}
      </div>
    );
  }
}

export default Tree;
