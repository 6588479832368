import React from "react";

function List({ data, renderItem, scrollable = false, eventHandlers }){

    // const style = { width: "100%" };

    // if (scrollable) {
    //   style.overflowY = "scroll";
    // }

    let items = data.map((d, idx) => renderItem(d, idx, eventHandlers));
    return <>{items}</>;
  
}

export default List;
