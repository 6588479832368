import React from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

import DetailsIcon from "@material-ui/icons/Details";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";

const KeywordFilterUI = ({ keywords, keywordFilter, onChange, renderValue }) => {
  const menuItems = keywords.map(name => (
      <MenuItem key={name} value={name}>
          <Checkbox checked={keywordFilter === name} />
          <ListItemText primary={name} />
      </MenuItem>
  ))

  return (
        <div
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          <DetailsIcon fontSize="small" />
          <div
            style={{
              flex: 1
            }}
          >
            <FormControl>
              {/* <InputLabel htmlFor="select-multiple-checkbox">Tag</InputLabel> */}
              <Select
                value={keywordFilter}
                onChange={onChange}
                input={<Input id="select-multiple-checkbox" />}
                renderValue={renderValue}
                /* MenuProps={MenuProps} */
              >
                {menuItems}
              </Select>
            </FormControl>
          </div>
        </div>);
};

export default KeywordFilterUI;
