import React from "react";
import Button from "@material-ui/core/Button";

const OKCancel = props => {
  const { onOk, onCancel } = props;
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ flex: 1 }}>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
      <div style={{ flex: 1 }}>
        <Button onClick={onOk}>Ok</Button>
      </div>
    </div>
  );
};

export default OKCancel;
