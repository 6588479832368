import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Redirect } from "react-router-dom"; // eslint-disable-line
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { createStore, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import thunk from "redux-thunk";
import motpwaApp from "./reducers/main";
import localforage from "localforage";
import { DBX_ACESS_TOKEN_KEY } from "./constants";
import { dbxAuthorize, accessTokenIsValid } from "./utilities/DropboxAuth";

import { registerDbxAccessToken, addNewNode } from "./actions";

import { createMuiTheme, makeStyles, ThemeProvider } from "@material-ui/core/styles";

const theme = createMuiTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 12,
    fontFamily: ['"Fira Code"',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Roboto"',
      '"Oxygen"',
      '"Ubuntu"',
      '"Cantarell"',
      '"Fira Sans"',
      '"Droid Sans"',
      '"Helvetica Neue"',
      'sans-serif']
  }
});

class Sender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const accessToken = await localforage.getItem(DBX_ACESS_TOKEN_KEY);
    if (accessToken) {
      const tokenIsValid = await accessTokenIsValid(accessToken);
      if (tokenIsValid) {
        console.log("user is logged in proceed to app");
        console.log(this.props);
        this.props.ifIsLoggedIn(accessToken);
        this.setState({ loggedIn: true });
      } else {
        console.log("user not logged in, do auth cycle");
      }
    } else {
      console.log("gonna have to get a new access token");
      dbxAuthorize();
    }
  }

  render() {
    return this.state.loggedIn ? <Redirect to={"/app"} /> : <div>Sender</div>;
  }
}

class Receiver extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { location, onAuthSuccess, onAuthError } = this.props;
    const { hash } = location;
    const pieces = hash.substr(1).split("&");
    const data = pieces.reduce((m, p) => {
      const [k, v] = p.split("=");
      m[k] = v;
      return m;
    }, {});
    console.log(data);
    this.setState({ access_token: data.access_token });
    onAuthSuccess(data.access_token);
    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!111");
    store.dispatch(registerDbxAccessToken(data.access_token));
  }

  render() {
    const { access_token } = this.state;

    return access_token ? (
      <Redirect to={"/app"} />
    ) : (
        <div>{"Receiver:" + access_token}</div>
      );
  }
}

let store, settings, DBXAccessToken;

class Root extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
      appIsReady: false,
      userIsLoggedIn: false
    };
  }

  handleSuccess = (accessToken /* , { response, state } */) => {
    console.log("Success!");
    this.setState({ accessToken, userIsLoggedIn: true });
    localforage.setItem(DBX_ACESS_TOKEN_KEY, accessToken);
  };

  handleError = async error => {
    console.error("Error: ", error.message);

    const text = await error.response.text();
    console.log(text);
  };

  // async _loadAssetsAsync() {
  //   // try {
  //   //   await cacheAssetsAsync({
  //   //     images: [require('./assets/images/expo-wordmark.png')],
  //   //     fonts: [
  //   //       //FontAwesome.font,
  //   //       { 'space-mono': require('./assets/fonts/SpaceMono-Regular.ttf') }
  //   //     ]
  //   //   });
  //   // } catch (e) {
  //   //   console.warn(
  //   //     'There was an error caching assets (see: main.js), perhaps due to a ' +
  //   //       'network timeout, so we skipped caching. Reload the app to try again.'
  //   //   );
  //   // } finally {
  //   // }
  // }
  async _loadAccessTokenAsync() {
    try {
      const value = await localforage.getItem(DBX_ACESS_TOKEN_KEY);
      if (value !== null) {
        DBXAccessToken = value;
      } else {
        console.log("no value for stored accessToken");
        DBXAccessToken = undefined;
      }
    } catch (error) {
      //Error retrieving data
      console.warn("error loading settings from AsyncStorage", error);
    }
  }

  async _loadSettingsAsync() {
    try {
      const value = await localforage.getItem("@motpwa:settings");
      if (value !== null) {
        console.log(value);
        settings = JSON.parse(value);
      } else {
        console.log("no value for stored settings");
        settings = undefined;
      }
    } catch (error) {
      //Error retrieving data
      console.warn("error loading settings from AsyncStorage", error);
    }
  }

  async _loadEverything() {
    try {
      await this._loadAccessTokenAsync();
      await this._loadSettingsAsync();
      // store = createStore(
      //   motpwaApp,
      //   ,
      //   
      // );
      store = configureStore({
        reducer: motpwaApp,
        preloadedState: { settings, dbxAccessToken: DBXAccessToken },
        enhancers: [applyMiddleware(thunk)]
      });
      // await this._loadAssetsAsync();
    } catch (e) {
      console.warn('error in "load everything"', e);
    } finally {
      // this.setState({ appIsReady: true });
    }
  }

  async componentWillMount() {
    console.log(this.state.accessToken);
    await this._loadEverything();

    // if (DBXAccessToken) {
    //   // console.log("FOUND ACCESSTOKEN");
    //   // const tokenIsValid = await accessTokenIsValid(DBXAccessToken);
    //   // console.log("ACCESSTOKEN IS VALID:", tokenIsValid);
    //   // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!222");
    //   // store.dispatch(registerDbxAccessToken(DBXAccessToken));
    //   // this.setState({ DBXAccessToken, userIsLoggedIn: true });
    // } else {
    //   console.log("NO ACCESSTOKEN IN STORAGE");
    // }
    this.setState({ appIsReady: true });
  }

  render() {
    const { appIsReady } = this.state;
    if (appIsReady) {
      return (
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <BrowserRouter>
              <div className="App-container" style={{ height: "100vh" }}>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <App />
                    /* <div> */
                    /*   {/\* { *\/} */
                    /*   {/\*   <Sender *\/} */
                    /*   {/\*     ifIsLoggedIn={this.handleSuccess} *\/} */
                    /*   {/\*     /\\* state={{ to: "/app" }} *\\/ *\/} */
                    /*   {/\*     /\\* render={({ url }) => <a href={url}>Connect to Dropbox</a>} *\\/ *\/} */
                    /*   {/\*   /> *\/} */
                    /*   {/\* } *\/} */
                    /* </div> */
                  )}
                />
                <Route
                  exact
                  path="/auth/dropbox"
                  render={({ location }) => (
                    <div>
                      <Receiver
                        location={location}
                        onAuthSuccess={this.handleSuccess}
                        onAuthError={this.handleError}
                      />
                      {/* <Receiver
                         location={location}
                         onAuthSuccess={this.handleSuccess}
                         onAuthError={this.handleError}
                         render={({ processing, state, error }) => {
                         if (processing) {
                         return <p>Processing!</p>;
                         }

                         if (error) {
                         return <p style={{ color: "red" }}>{error.message}</p>;
                         }

                         return <Redirect to={state.to} />;
                         }}
                         /> */}
                    </div>
                  )}
                />

                <Route
                  exact
                  path="/app"
                  render={() => {
                    if (this.state.userIsLoggedIn) {
                      return <App />;
                    } else {
                      return <Redirect to={"/"} />;
                    }
                  }}
                />
              </div>
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      );
    } else {
      return <div>LOADING....</div>;
    }
  }
}

// class AppContainer extends React.Component {
//   state = {
//     appIsReady: false,
//     userIsLoggedIn: false
//   };

//   async _loadEverything() {
//     // try {
//     //   await this._loadSettingsAsync();
//     //   store = createStore(
//     //     mobileOrgTooApp,
//     //     { settings },
//     //     applyMiddleware(thunk)
//     //   );
//     //   await this._loadAssetsAsync();
//     // } catch (e) {
//     //   console.warn('error in "load everything"', e);
//     // } finally {
//     this.setState({ appIsReady: true });
//     // }
//   }

//   componentWillMount() {
//     this._loadEverything();
//   }

//   render() {
//     if (this.state.appIsReady) {
//       // window.open("http://www.google.com");
//       // return <iframe src={authUrl} />;
//       window.open(authUrl, "CNN_WindowName");
//       return <div>dbxService</div>;
//     } else {
//       return <div>loading...</div>;
//     }
//   }
// }

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
